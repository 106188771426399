import { apiClient } from './client'
import { identityServicePaths } from './constants'

export interface LoginValues {
  email: string
  password: string
  code?: string
  recoveryCode?: string
  remember: boolean
}

export const doLogin = async ({ email, password, code, recoveryCode, remember }: LoginValues) => {
  const { data } = await apiClient.post(
    identityServicePaths.login,
    {
      email: (email ?? '').trim(),
      password,
      code,
      recovery_code: recoveryCode,
      remember,
    },
    { withCredentials: true },
  )

  const accessTokens = data?.data?.access_tokens

  if (accessTokens) {
    return Promise.all(
      accessTokens.map((value: string) =>
        apiClient.post(
          value,
          {
            email,
            password,
            code,
            recovery_code: recoveryCode,
            remember,
          },
          { withCredentials: true },
        ),
      ),
    )
  }
}
