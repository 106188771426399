import { Button, colors } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { Title } from 'components/shared/components'

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
`
export const StyledTitle = styled(Title)`
  margin-bottom: 62px !important;
`

export const StyledUL = styled.ul`
  padding-left: 10px;
  margin-top: 25px;
  margin-bottom: 35px;
`

export const SecondaryButton = styled(Button)`
  margin-right: 16px;
  width: 100%;
  height: 40px;
`
export const PrimaryButton = styled(Button)`
  width: 100%;
  height: 40px;
`

export const StyledLI = styled.li`
  margin-bottom: 16px;
  ::marker {
    color: ${colors.padGrey[300]};
  }
`

export const LoadingContainer = styled.div`
  padding: 30px 0;
`
