import { InputNumberProps } from '@paddle/ant-component-library'
import { Form as FormikForm } from 'formik'
import styled from 'styled-components'

export const Form = styled(FormikForm)`
  margin-top: 30px;
`

export const FormRow = styled.div`
  margin-bottom: 16px;
`

export enum InputType {
  Numeric = 'Numeric',
  String = 'String',
  Password = 'Password',
}

export enum LayoutType {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface InputFieldProps {
  'aria-label': string
  layout: any
  disabled: boolean
  id: string
  name: string
  label: string
  required?: boolean
  colon?: boolean
  type?: InputType[keyof InputType]
  min?: number
  max?: number
  step?: number
  layoutType?: LayoutType
  precision?: number
  formatter?: (value: string | number | undefined) => string
  parser?: InputNumberProps['parser']
  inputStyle?: any
  placeholder?: string
  maxLength?: number
  isPassword?: boolean
  hideErrorMessage?: boolean
  autoFocus?: boolean
  autoComplete?: boolean
  extra?: string
  addonBefore?: string
}
