import { InfoConstants } from 'components/pages/info-page/constants'

export const SMALL_BREAKPOINT_PX = 834

export const serviceNamePlaceholder = '{{ serviceName }}'
const serviceNameRegex = new RegExp(serviceNamePlaceholder, 'g')

export const getCopyWithServiceName = (copy: string, serviceName?: string, defaultCopy?: string) =>
  !!serviceName ? copy.replace(serviceNameRegex, serviceName) : defaultCopy

export const isValidServiceName = (serviceName: string) => {
  return (
    !serviceName ||
    Object.keys(InfoConstants.Copy)
      .map((key) => key.toLocaleLowerCase())
      .includes(serviceName.toLocaleLowerCase())
  )
}
