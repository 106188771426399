import { AxiosError } from 'axios'

import {
  correlationIDText,
  SignupErrorFieldLabels,
  SignupErrorFields,
  SignupErrorMessages,
  SignupFieldLabels,
  SignupFormBusinessTypeValues,
  SignupWebsitePrefix,
} from 'components/pages/sign-up/constants'
import { SignupFormRequestObject, SignupFormValues } from 'components/pages/sign-up/types'
import { HUBSPOT_COOKIE_NAME } from 'constants/shared'
import { apiClient } from 'modules/api/client'
import { identityServicePaths } from 'modules/api/constants'

function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length && parts.length === 2) return parts.pop()?.split(';').shift()
  return null
}

export function convertFormValuesToAPI(values: SignupFormValues): SignupFormRequestObject {
  return {
    accepted_terms_of_service: values.acceptedTermsOfService,
    annual_revenue: values.annualRevenue,
    signup_version: values.signUpVersion,
    business:
      values.signUpVersion === 'extended'
        ? {
            address: {
              city: (values.city ?? '').trim(),
              country_code: values.country,
              first_line: (values.streetAddress ?? '').trim(),
              postal_code: (values.postcode ?? '').trim(),
              second_line: (values.apartment ?? '').trim(),
            },
            name: (values.businessName ?? '').trim(),
            registration_number: values.registrationNumber,
            stock_ticker_symbol: values.businessType === SignupFormBusinessTypeValues.Public ? values.stock : '',
            type: values.businessType,
          }
        : undefined,
    user: {
      first_name: (values.firstName ?? '').trim(),
      last_name: (values.lastName ?? '').trim(),
      marketing_consent: values.marketingConsent,
      email: (values.email ?? '').trim(),
      password: values.password,
    },
    website: values.website
      ? values.website.trim().startsWith(SignupWebsitePrefix)
        ? values.website
        : SignupWebsitePrefix + values.website
      : '',
    product_categories: values.products,
    utm: {
      ref: values.utm.ref,
      utm_campaign: values.utm.campaign,
      utm_content: values.utm.content,
      utm_medium: values.utm.medium,
      utm_source: values.utm.source,
      utm_term: values.utm.term,
    },
  }
}

export async function postSeller(formValues: SignupFormValues, isUserAuthenticationCalloutShown: boolean) {
  const formValuesObject = convertFormValuesToAPI(formValues)
  const hubspotVisitorId = getCookie(HUBSPOT_COOKIE_NAME)
  const requestObject = {
    ...formValuesObject,
    authenticated_callout_shown: !!isUserAuthenticationCalloutShown,
    hubspot_visitor_id: hubspotVisitorId,
  }
  return apiClient.post(identityServicePaths.signup, requestObject, { withCredentials: true })
}

interface SignupErrors {
  field: string
  message: string
}
export interface SignupErrorResponse {
  meta?: { request_id: string }
  error: {
    code: string
    errors: SignupErrors[]
  }
}
export function getSignupErrorMessage(error: AxiosError<SignupErrorResponse>) {
  if (error?.response?.status === 400) {
    const errorObject = error.response.data?.error
    if (errorObject?.code === SignupErrorFields.InvalidField) {
      const errorFields = errorObject.errors.map((errorField: SignupErrors) => SignupErrorFieldLabels[errorField.field])
      const errorFieldNames = errorFields.length > 1 ? errorFields.join(', ') : errorFields[0]
      if (errorFieldNames) {
        return errorFieldNames.includes(SignupFieldLabels.BusinessName) ||
          errorFieldNames.includes(SignupFieldLabels.EmailAddress)
          ? SignupErrorMessages[errorFieldNames] ?? SignupErrorMessages.CreationFailureMessage
          : `${errorFieldNames} ${SignupErrorMessages.CreationFailureDuplicateMessageSuffix}`
      } else {
        return SignupErrorMessages.CreationFailureMessage
      }
    }
  }
  const hasCorrelationID = !!error?.response?.data?.meta?.request_id
  return hasCorrelationID
    ? `${SignupErrorMessages.CreationFailureMessage} ${correlationIDText} ${error?.response?.data?.meta?.request_id}`
    : SignupErrorMessages.CreationFailureMessage
}
