export const COOKIE_REDIRECT_LOCATION_KEY = 'redirectLocation'

export const HUBSPOT_COOKIE_NAME = 'hubspotutk'

export const EnvironmentConstant = {
  Production: 'production',
  Sandbox: 'sandbox',
  Staging: 'staging',
  Development: 'development',
  Local: 'local',
} as const
