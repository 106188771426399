import { Typography } from '@paddle/ant-component-library'
import { ReactNode } from 'react'
import styled from 'styled-components'

import {
  SignUpLayoutConstant,
  SignUpLayoutStyledConstant,
} from 'components/pages/sign-up/sign-up-page-layout/constants'
import Chevron from 'components/pages/sign-up/sign-up-page-layout/images/chevron.svg?react'
import { MobileDrawerState, MobileDrawerStyledProps } from 'components/pages/sign-up/sign-up-page-layout/types'

// the animations are to avoid the iubenda lock icon
// we hide this whole component on desktop
const StyledDivContainer = styled.div<MobileDrawerStyledProps>`
  // we need to avoid the iubenda lock icon width 34px
  margin-left: 34px;

  transition: margin 0.2s linear;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${({ $drawerState }) => $drawerState === 'expanded' && `margin: 0;`}

  // desktop
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    display: none;
  }
`

const StyledButton = styled.button<MobileDrawerStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 20px 32px;
  width: 100%;

  cursor: pointer;

  background: none;
  border: none;
`

const StyledTitle = styled(Typography.Title)`
  && {
    color: white;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`

const StyledMobileHeaderChevron = styled(Chevron)<MobileDrawerStyledProps>`
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  transform: rotate(${({ $drawerState }) => ($drawerState === 'expanded' ? -180 : 0)}deg);
  transition: transform 0.2s ease-out;
`

interface Props {
  buttonRef: React.RefObject<HTMLButtonElement>
  drawerState: MobileDrawerState
  onClick: () => void
  title: ReactNode
}

export const SignUpAsideMobileButton = (props: Props) => {
  const { buttonRef, drawerState, onClick, title } = props
  return (
    <StyledDivContainer $drawerState={drawerState}>
      <StyledButton
        aria-controls={SignUpLayoutConstant.DrawerId}
        aria-expanded={drawerState === 'expanded'}
        $drawerState={drawerState}
        onClick={onClick}
        ref={buttonRef}
      >
        <StyledTitle level={4}>{title}</StyledTitle>

        <StyledMobileHeaderChevron
          aria-hidden="true"
          $drawerState={drawerState}
          width={24}
          height={24}
          title={
            drawerState === 'expanded'
              ? SignUpLayoutConstant.AsideMobileExpandedIconAlt
              : SignUpLayoutConstant.AsideMobileCollapsedIconAlt
          }
        />
      </StyledButton>
    </StyledDivContainer>
  )
}
