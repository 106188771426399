export function DigitalProductsIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_343_20262" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 31.9381C3.05369 31.446 0 28.0796 0 24C0 19.8555 3.15157 16.4471 7.18892 16.0406C7.06487 15.3794 7 14.6973 7 14C7 7.92487 11.9249 3 18 3C22.7181 3 26.7424 5.97038 28.3048 10.1431C28.8558 10.049 29.4222 10 30 10C34.8729 10 38.9317 13.4853 39.8196 18.0991C40.2033 18.0339 40.5977 18 41 18C44.866 18 48 21.134 48 25C48 28.866 44.866 32 41 32H8H7V31.9381Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 31.9381C3.05369 31.446 0 28.0796 0 24C0 19.8555 3.15157 16.4471 7.18892 16.0406C7.06487 15.3794 7 14.6973 7 14C7 7.92487 11.9249 3 18 3C22.7181 3 26.7424 5.97038 28.3048 10.1431C28.8558 10.049 29.4222 10 30 10C34.8729 10 38.9317 13.4853 39.8196 18.0991C40.2033 18.0339 40.5977 18 41 18C44.866 18 48 21.134 48 25C48 28.866 44.866 32 41 32H8H7V31.9381Z"
        fill="#D2D4DE"
      />
      <path
        d="M7 31.9381H8V31.0551L7.12374 30.9458L7 31.9381ZM7.18892 16.0406L7.2891 17.0356L8.37254 16.9265L8.17177 15.8562L7.18892 16.0406ZM28.3048 10.1431L27.3683 10.4938L27.6582 11.268L28.4732 11.1288L28.3048 10.1431ZM39.8196 18.0991L38.8376 18.2881L39.0225 19.2488L39.987 19.085L39.8196 18.0991ZM7 32H6V33H7V32ZM7.12374 30.9458C3.67174 30.5153 1 27.5689 1 24H-1C-1 28.5903 2.43564 32.3767 6.87626 32.9304L7.12374 30.9458ZM1 24C1 20.3742 3.75749 17.3912 7.2891 17.0356L7.08874 15.0456C2.54565 15.5031 -1 19.3368 -1 24H1ZM8.17177 15.8562C8.05908 15.2555 8 14.6351 8 14H6C6 14.7594 6.07067 15.5032 6.20606 16.225L8.17177 15.8562ZM8 14C8 8.47715 12.4772 4 18 4V2C11.3726 2 6 7.37258 6 14H8ZM18 4C22.2876 4 25.9473 6.69883 27.3683 10.4938L29.2413 9.79242C27.5374 5.24194 23.1486 2 18 2V4ZM28.4732 11.1288C28.9688 11.0442 29.4788 11 30 11V9C29.3655 9 28.7429 9.05382 28.1365 9.15735L28.4732 11.1288ZM30 11C34.3845 11 38.0386 14.1362 38.8376 18.2881L40.8015 17.9101C39.8248 12.8345 35.3613 9 30 9V11ZM39.987 19.085C40.3157 19.0292 40.654 19 41 19V17C40.5413 17 40.091 17.0387 39.6521 17.1132L39.987 19.085ZM41 19C44.3137 19 47 21.6863 47 25H49C49 20.5817 45.4183 17 41 17V19ZM47 25C47 28.3137 44.3137 31 41 31V33C45.4183 33 49 29.4183 49 25H47ZM41 31H8V33H41V31ZM8 31H7V33H8V31ZM8 32V31.9381H6V32H8Z"
        fill="#73809C"
        mask="url(#path-1-inside-1_343_20262)"
      />
      <rect x="11.5" y="22.5" width="25" height="25" rx="1.5" fill="white" stroke="#73809C" />
      <path
        d="M27 41.1561L27 41.8394C27.4182 41.8395 27.8322 41.9213 28.2185 42.0803C28.6048 42.2392 28.9557 42.4721 29.2511 42.7656C29.5466 43.0591 29.7809 43.4075 29.9405 43.7909C30.1002 44.1743 30.1821 44.5852 30.1816 45L30.8184 45C30.8186 44.163 31.1539 43.3604 31.7505 42.7685C32.3471 42.1767 33.1562 41.8441 34 41.8439L34 41.1606C33.5818 41.1605 33.1678 41.0787 32.7815 40.9197C32.3952 40.7608 32.0443 40.5279 31.7489 40.2344C31.4534 39.9409 31.2191 39.5925 31.0595 39.2091C30.8998 38.8257 30.8179 38.4148 30.8184 38L30.1816 38C30.1814 38.837 29.8461 39.6396 29.2495 40.2315C28.6529 40.8233 27.8438 41.1559 27 41.1561Z"
        fill="#73809C"
      />
      <rect x="14" y="41" width="7" height="1" rx="0.2" fill="#73809C" />
      <rect x="14" y="44" width="7" height="1" rx="0.2" fill="#73809C" />
      <path
        d="M23.735 29.076L20.5 31.0979V22.5H27.5V31.0979L24.265 29.076L24 28.9104L23.735 29.076Z"
        fill="#A2ABBD"
        stroke="#73809C"
      />
      <circle cx="24" cy="16" r="1.5" fill="white" stroke="#73809C" />
      <path d="M37 12L32.2335 15.8998C31.7693 16.2797 31.5 16.8479 31.5 17.4478V22" stroke="#73809C" />
      <path d="M12 13L15.7279 15.8995C16.2151 16.2784 16.5 16.861 16.5 17.4782V23" stroke="#73809C" />
      <circle cx="36" cy="13" r="1.5" fill="white" stroke="#73809C" />
      <circle cx="12" cy="13" r="1.5" fill="white" stroke="#73809C" />
      <path d="M24 17.5V22" stroke="#73809C" />
      <path
        d="M27 25.2C27 25.0895 27.0895 25 27.2 25H36.8C36.9105 25 37 25.0895 37 25.2V25.8C37 25.9105 36.9105 26 36.8 26H27.2C27.0895 26 27 25.9105 27 25.8V25.2Z"
        fill="#73809C"
      />
      <path
        d="M11 25.2C11 25.0895 11.0895 25 11.2 25H20.8C20.9105 25 21 25.0895 21 25.2V25.8C21 25.9105 20.9105 26 20.8 26H11.2C11.0895 26 11 25.9105 11 25.8V25.2Z"
        fill="#73809C"
      />
    </svg>
  )
}
