import { Button, Typography } from '@paddle/ant-component-library'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { Container, MainContent, Title } from '../../shared/components'
import { OAuthErrorConstants } from './constants'
import OAuthFailedSVG from './oauth-failed.svg?react'

const StyledTitle = styled(Title)`
  display: block;
  margin-bottom: 20px !important;
`

const CTA = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 40px 0;

  &.ant-btn {
    padding: 4px;
  }
`

const StyledOAuthFailed = styled(OAuthFailedSVG)`
  display: block;
  margin: auto;
  margin-bottom: 32px;
`

export const OAuthErrorPage = () => {
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error_description')

  return (
    <MainContent data-testid={'oauth-error-page'}>
      <Container>
        <StyledOAuthFailed title={'OAuth failed'} />
        <StyledTitle>{OAuthErrorConstants.Title}</StyledTitle>
        <Typography.Text>{error}</Typography.Text>

        <CTA type="primary" href="https://paddle.com/">
          {OAuthErrorConstants.CTA}
        </CTA>
      </Container>
    </MainContent>
  )
}
