import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { environment } from 'environment'
import { redirect, safeRedirect } from 'libraries/redirect'
import { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { routes } from 'constants/routes'
import { COOKIE_REDIRECT_LOCATION_KEY } from 'constants/shared'
import { identityServicePaths } from 'modules/api/constants'
import { ApiErrorResponse, useGetFirstApiError } from 'modules/api/hooks'
import { doLogin, LoginValues } from 'modules/api/login'

import { LoginErrorConstant } from '../../../constants/api-errors'

export const useLoginMutation = () => {
  const [needMoreAction, setNeedMoreAction] = useState(false)
  const navigate = useNavigate()
  const [cookies, , deleteCookie] = useCookies([COOKIE_REDIRECT_LOCATION_KEY])

  const { error, onErrorCallback, removeErrorCallback, setError } = useGetFirstApiError()
  const { isError, mutate, isSuccess, isPending } = useMutation({
    mutationFn: (values: LoginValues) => doLogin(values),
    onError: (error: AxiosError<ApiErrorResponse>, variables) => {
      const code = error?.response?.data?.error?.code

      if (!code) {
        onErrorCallback(error)
        return
      }

      if (code.startsWith(LoginErrorConstant.passwordResetRequired)) {
        setNeedMoreAction(true)
        navigate(`${routes.passwordResetRequired}`, {
          state: {
            email: variables.email,
            reason: parseInt(code.replace(LoginErrorConstant.passwordResetRequired + '.', ''), 10),
          },
        })
        return
      }

      if (code === LoginErrorConstant.employeeOktaRequired) {
        setNeedMoreAction(true)
        let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY] ?? environment.FALLBACK_REDIRECT_URL

        let employeeOktaUrl = new URL(environment.BASE_URL)
        employeeOktaUrl.pathname = identityServicePaths.employeeLoginPath
        employeeOktaUrl.searchParams.set('r', redirectLocation)
        employeeOktaUrl.searchParams.set('rem', String(variables.remember))
        redirect(employeeOktaUrl.toString())
        return
      }

      if (code === LoginErrorConstant.mfaMissing) {
        setNeedMoreAction(true)
        navigate(`${routes.twoFA}${window.location.search}`, {
          state: { email: variables.email, password: variables.password, remember: variables.remember },
        })
        return
      }

      onErrorCallback(error)
    },
    onSuccess: () => {
      let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY] ?? environment.FALLBACK_REDIRECT_URL
      deleteCookie(COOKIE_REDIRECT_LOCATION_KEY)
      safeRedirect(redirectLocation, navigate)
    },
  })

  const onSubmit = useCallback(
    async (values: LoginValues) => {
      removeErrorCallback()
      mutate(values)
    },
    [mutate, removeErrorCallback],
  )

  return {
    onSubmit,
    isError,
    isPending,
    isSuccess,
    needMoreAction,
    setError,
    errorCode: error?.code,
  }
}
