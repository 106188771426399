import { Button } from '@paddle/ant-component-library'
import styled from 'styled-components'

export const StyledLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export const CTA = styled(Button)`
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
`

export const StyledEndAlignWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
