import { colors } from '@paddle/ant-component-library'
import { SentryRoutesWrapper } from 'libraries/sentry'
import { Navigate, Route } from 'react-router-dom'
import styled from 'styled-components'

import { routes } from '../../constants/routes'
import {
  ConsentPage,
  InfoPage,
  LoginPage,
  PermissionErrorPage,
  RecoveryPage,
  RedirectPage,
  ResetPasswordPage,
  TwoFAPage,
  OAuthErrorPage,
} from '../pages'
import { ResetPasswordRequiredPage } from '../pages/reset-password-required'
import { colors as newBrandColors } from '../shared/colors'
import { SMALL_BREAKPOINT_PX } from '../shared/helpers'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    flex-direction: column;
    flex: 1;
  }
`

const StyledLeftSection = styled.div`
  min-height: 100vh;
  flex: 0 0 50%;
  background:
    url('/images/circles-bg.svg') left bottom no-repeat,
    linear-gradient(220deg, ${colors.padBlue[800]}, ${newBrandColors.black});
  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    flex: 0 0 auto;
    min-height: auto;
  }
`

const StyledRightSection = styled.div`
  min-height: 100vh;
  flex: 0 0 50%;
  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    min-height: auto;
    flex: 1;
  }
`

export function PaddleClassicRoutes() {
  return (
    <StyledWrapper data-testid={'wrapper'}>
      <StyledLeftSection data-testid={'left-section'}>
        <InfoPage />
      </StyledLeftSection>

      <StyledRightSection data-testid={'right-section'}>
        <SentryRoutesWrapper>
          <Route path={routes.root} element={<Navigate to={routes.login} />} />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.passwordReset} element={<ResetPasswordPage />} />
          <Route path={routes.twoFA} element={<TwoFAPage />} />
          <Route path={routes.passwordResetRequired} element={<ResetPasswordRequiredPage />} />
          <Route path={routes.recovery} element={<RecoveryPage />} />
          <Route path={routes.consent} element={<ConsentPage />} />
          <Route path={routes.permissionError} element={<PermissionErrorPage />} />
          <Route path={routes.oauthError} element={<OAuthErrorPage />} />
          <Route path={routes.redirect} element={<RedirectPage />} />
        </SentryRoutesWrapper>
      </StyledRightSection>
    </StyledWrapper>
  )
}
