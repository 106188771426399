import { environment } from 'environment'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { isWebUri } from 'valid-url'

import { routes } from '../../constants/routes'

export const redirect = (location: string) => {
  window.location.href = location
}

// Checks is the redirect location is safe, i.e. is a trusted URL.
// If the URL is safe then redirects to the provided URL.
// If the URL is not safe then navigates to a page to confirm the redirect location.
export const safeRedirect = (redirectLocation: string | undefined, navigate: NavigateFunction) => {
  // if no redirect location is provided, then we can redirect to the fallback one
  if (!redirectLocation || !isWebUri(redirectLocation)) {
    redirect(environment.FALLBACK_REDIRECT_URL)
    return
  }

  // the redirect location may contain a query string.
  // we remove this before checking for a match in the allowed list, as it is valid for the redirect location to contain
  // an arbitrary query string as long as the host and path are allowed
  const redirectLocationWithoutSearch = redirectLocation.replace(new URL(redirectLocation).search, '')
  const redirectAllowed = environment.ALLOWED_OAUTH_REDIRECTS.some(
    (allowedRedirect) => redirectLocationWithoutSearch === allowedRedirect,
  )

  if (redirectAllowed) {
    redirect(redirectLocation)
  } else {
    navigate(routes.redirect + '?r=' + encodeURIComponent(redirectLocation))
  }
}
