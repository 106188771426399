import { LoadingSpinner, SpinnerSize } from '@paddle/ant-component-library'
import { environment } from 'environment'
import { Formik, Field } from 'formik'
import { useEffect } from 'react'
import { object, string } from 'yup'

import {
  SignUp,
  MainContent,
  Container,
  Form,
  Title,
  FormRow,
  InputField,
  CheckboxField,
  ApiError,
  LoadingContainer,
  LoadingContentWrapper,
} from 'components/shared/components'

import { LoginConstants } from './constants'
import { useLoginMutation } from './hooks'
import { CTA, StyledEndAlignWrapper, StyledLink } from './login.styled'

const initialValues = { email: '', password: '', remember: false }

const validationSchema = object().shape({
  email: string().trim().email(LoginConstants.EmailError).required(LoginConstants.EmailRequiredError),
  password: string().required(LoginConstants.PasswordRequiredError),
})

export const LoginPage = () => {
  const { onSubmit, errorCode, isPending, isSuccess, setError, needMoreAction } = useLoginMutation()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const error = params.get('error')
    if (error) {
      setError({
        code: error,
        status: '',
        type: '',
      })
    }
  }, [setError])

  const isProcessing = isPending || isSuccess || needMoreAction

  return (
    <MainContent data-testid="login-page">
      <Container>
        <Title>{LoginConstants.Title}</Title>

        <LoadingContentWrapper isLoading={isProcessing}>
          {isProcessing ? (
            <LoadingContainer>
              <LoadingSpinner size={SpinnerSize.Medium} />
            </LoadingContainer>
          ) : null}

          <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              <ApiError errorCode={errorCode || ''} />
              <FormRow>
                <Field
                  name="email"
                  id="email-input"
                  disabled={isProcessing}
                  required={true}
                  component={InputField}
                  placeholder={LoginConstants.EmailAddress}
                  autoFocus={true}
                />
              </FormRow>

              <FormRow>
                <Field
                  type="password"
                  name="password"
                  id="password-input"
                  disabled={isProcessing}
                  required={true}
                  component={InputField}
                  placeholder={LoginConstants.Password}
                  isPassword={true}
                />
              </FormRow>

              <FormRow>
                <Field
                  type="checkbox"
                  name="remember"
                  id="remember-input"
                  disabled={isProcessing}
                  component={CheckboxField}
                  label={LoginConstants.RememberMe}
                />
              </FormRow>

              <CTA type="primary" htmlType="submit" loading={isProcessing} disabled={isProcessing}>
                {LoginConstants.CTA}
              </CTA>
            </Form>
          </Formik>
        </LoadingContentWrapper>
        <StyledEndAlignWrapper>
          <StyledLink href={environment.RESET_ENDPOINT}>{LoginConstants.ForgotPassword}</StyledLink>
        </StyledEndAlignWrapper>
      </Container>

      <StyledEndAlignWrapper>
        <SignUp />
      </StyledEndAlignWrapper>
    </MainContent>
  )
}
