export function PhysicalProductsIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.5 14.5H38.5393C38.9639 14.5 39.3686 14.6799 39.653 14.9952L47.1137 23.2641C47.3624 23.5397 47.5 23.8977 47.5 24.2689V31C47.5 31.8284 46.8284 32.5 46 32.5H33.5V14.5Z"
        fill="#D2D4DE"
        stroke="#73809C"
      />
      <circle cx="41" cy="32" r="3.5" fill="#D2D4DE" stroke="#73809C" />
      <path
        d="M7.5 8C7.5 7.17157 8.17157 6.5 9 6.5H33C33.8284 6.5 34.5 7.17157 34.5 8V32.5H7.5V8Z"
        fill="#D2D4DE"
        stroke="#73809C"
      />
      <circle cx="18.5" cy="28.5" r="3" fill="white" stroke="#73809C" />
      <rect x="0.5" y="20.5" width="25" height="25" rx="1.5" fill="white" stroke="#73809C" />
      <path
        d="M16 39.1561L16 39.8394C16.4182 39.8395 16.8322 39.9213 17.2185 40.0803C17.6048 40.2392 17.9557 40.4721 18.2511 40.7656C18.5466 41.0591 18.7809 41.4075 18.9405 41.7909C19.1002 42.1743 19.1821 42.5852 19.1816 43L19.8184 43C19.8186 42.163 20.1539 41.3604 20.7505 40.7685C21.3471 40.1767 22.1562 39.8441 23 39.8439L23 39.1606C22.5818 39.1605 22.1678 39.0787 21.7815 38.9197C21.3952 38.7608 21.0443 38.5279 20.7489 38.2344C20.4534 37.9409 20.2191 37.5925 20.0595 37.2091C19.8998 36.8257 19.8179 36.4148 19.8184 36L19.1816 36C19.1814 36.837 18.8461 37.6396 18.2495 38.2315C17.6529 38.8233 16.8438 39.1559 16 39.1561Z"
        fill="#73809C"
      />
      <rect x="3" y="39" width="7" height="1" rx="0.2" fill="#73809C" />
      <rect x="3" y="42" width="7" height="1" rx="0.2" fill="#73809C" />
      <path
        d="M12.735 27.076L9.5 29.0979V20.5H16.5V29.0979L13.265 27.076L13 26.9104L12.735 27.076Z"
        fill="#A2ABBD"
        stroke="#73809C"
      />
      <rect x="3" y="10" width="2" height="1" rx="0.2" fill="#73809C" />
      <rect y="13" width="5" height="1" rx="0.2" fill="#73809C" />
      <path
        d="M28 37.2C28 37.0895 28.0895 37 28.2 37H47.8C47.9105 37 48 37.0895 48 37.2V37.8C48 37.9105 47.9105 38 47.8 38H28.2C28.0895 38 28 37.9105 28 37.8V37.2Z"
        fill="#73809C"
      />
      <path
        d="M16 23.2C16 23.0895 16.0895 23 16.2 23H25.8C25.9105 23 26 23.0895 26 23.2V23.8C26 23.9105 25.9105 24 25.8 24H16.2C16.0895 24 16 23.9105 16 23.8V23.2Z"
        fill="#73809C"
      />
      <path
        d="M0 23.2C0 23.0895 0.089543 23 0.2 23H9.8C9.91046 23 10 23.0895 10 23.2V23.8C10 23.9105 9.91046 24 9.8 24H0.2C0.0895429 24 0 23.9105 0 23.8V23.2Z"
        fill="#73809C"
      />
      <path d="M39 17V23C39 23.5523 39.4477 24 40 24H45.5" stroke="#73809C" />
    </svg>
  )
}
