import {
  AlertNGB,
  Checkbox,
  CheckboxChangeEvent,
  Col,
  ExclamationCircleFilled,
  InfoCircleFilled,
  Row,
  Typography,
} from '@paddle/ant-component-library'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  ProductSelectionList,
  RestrictedProductsList,
  SignupFieldLabels,
  SignupFieldNames,
  SignupProductSelectionConstants,
  UnSupportedProductsList,
} from 'components/pages/sign-up/constants'
import { SignUpProductCard } from 'components/pages/sign-up/sign-up-product-card'
import { SignupFormValues } from 'components/pages/sign-up/types'

const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

const StyledH3 = styled.h3`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`

const StyledSection = styled.section`
  margin-top: 40px;
`

const StyledRow = styled(Row)`
  margin-top: 40px;
`

const StyledWarningAlert = styled(AlertNGB)`
  && {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
`

export function SignupProductSelection() {
  const { setFieldValue, values } = useFormikContext<SignupFormValues>()

  const hasAllProductsUnSupported =
    values.products.length > 0 && values.products.every((product) => UnSupportedProductsList.includes(product))

  const hasAllRestrictedProducts =
    values.products.length > 0 && values.products.every((product) => RestrictedProductsList.includes(product))

  const hasUnSupportedProducts =
    values.products.length > 0 && values.products.some((product) => UnSupportedProductsList.includes(product))

  const hasRestrictedProducts =
    values.products.length > 0 && values.products.some((product) => RestrictedProductsList.includes(product))

  const UnSupportedProducts =
    values.products.length > 0 ? values.products.filter((product) => UnSupportedProductsList.includes(product)) : []

  const ProductSections = [
    { title: 'Categories we support:', supported: true },
    { title: 'Categories with more restrictions:', supported: false },
  ]

  const ProductRestrictionWarning = (
    <Typography.Text>
      We have restrictions against {hasAllRestrictedProducts ? '' : 'some of'} the products you sell.{' '}
      <a href="https://www.paddle.com/demo" target="_blank" rel="noreferrer">
        Contact sales
      </a>{' '}
      for more guidance around this, or continue to create an account to explore our Dashboard.
    </Typography.Text>
  )

  const UnSupportedProductWarning = UnSupportedProducts.length ? (
    <Typography.Text>
      Unfortunately, we don&apos;t support{' '}
      {UnSupportedProducts.map((product) => ProductSelectionList.find((p) => p.id === product)?.title ?? product)
        .reduce((a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b)
        .toLowerCase()}{' '}
      but you can continue to create an account for your other selected categories.
    </Typography.Text>
  ) : null

  const warningMessage = hasAllProductsUnSupported
    ? SignupProductSelectionConstants.AllProductsAreUnsupported
    : hasUnSupportedProducts
      ? UnSupportedProductWarning
      : SignupProductSelectionConstants.SomeProductsAreUnsupported

  return (
    <>
      {ProductSections.map(({ title, supported }, index) => (
        <StyledSection key={index}>
          <StyledH3>{title}</StyledH3>
          <Row gutter={[20, 20]}>
            {ProductSelectionList.filter((p) => p.supported === supported).map(({ tooltip, id, title }) => {
              return (
                <Col key={id} span={12}>
                  <Field
                    aria-label={SignupFieldLabels.Products}
                    value={values.products}
                    onChange={setFieldValue}
                    name={SignupFieldNames.Products}
                    component={SignUpProductCard}
                    id={id}
                    tooltip={tooltip}
                    title={title}
                  />
                </Col>
              )
            })}
          </Row>
        </StyledSection>
      ))}
      {hasUnSupportedProducts && (
        <StyledRow>
          <Col span={24}>
            <StyledWarningAlert
              aria-label={SignupProductSelectionConstants.ValidationAlertLabel}
              description={warningMessage}
              type="error"
              showIcon
              icon={<ExclamationCircleFilled />}
            />
          </Col>
        </StyledRow>
      )}
      {hasRestrictedProducts && (
        <StyledRow>
          <Col span={24}>
            <StyledWarningAlert
              aria-label={SignupProductSelectionConstants.ValidationAlertLabel}
              description={ProductRestrictionWarning}
              type="warning"
              showIcon
              icon={<InfoCircleFilled />}
            />
          </Col>
        </StyledRow>
      )}
      <StyledRow>
        <Col span={24}>
          <Checkbox
            checked={values.acceptedTermsOfService}
            onChange={(e: CheckboxChangeEvent) => setFieldValue(SignupFieldNames.TermsAndConditions, e.target.checked)}
          >
            {SignupProductSelectionConstants.FooterText}{' '}
            <StyledLink href={SignupProductSelectionConstants.AUPPolicyLink} target="_blank" rel="noreferrer">
              {SignupProductSelectionConstants.AUPPolicy}
            </StyledLink>
            ,{' '}
            <StyledLink href={SignupProductSelectionConstants.TermsLink} target="_blank" rel="noreferrer">
              {SignupProductSelectionConstants.Terms}
            </StyledLink>{' '}
            &{' '}
            <StyledLink href={SignupProductSelectionConstants.PrivacyPolicyLink} target="_blank" rel="noreferrer">
              {SignupProductSelectionConstants.PrivacyPolicy}
            </StyledLink>
          </Checkbox>
        </Col>
      </StyledRow>
    </>
  )
}
