import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  SignUpLayoutConstant,
  SignUpLayoutStyledConstant,
} from 'components/pages/sign-up/sign-up-page-layout/constants'
import { SignUpAsideMobileButton } from 'components/pages/sign-up/sign-up-page-layout/sign-up-aside-mobile-button'
import { MobileDrawerState, MobileDrawerStyledProps } from 'components/pages/sign-up/sign-up-page-layout/types'

const StyledHr = styled.hr`
  border-bottom: 1px solid white;
  border-top: 0;
  opacity: 0.24;
  margin-inline: 32px;
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    display: none;
  }
`

const StyledAside = styled.aside`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 101;
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    position: relative;
    z-index: 100;
  }
`

// on mobile, it changes to be an absolute positioned
// drawer at the bottom of the screen
const StyledDivContainer = styled.div`
  // shared properties
  overflow: hidden;
  background-image: url('images/aside-bg.svg');
  background-size: cover;
  background-color: black;

  // mobile styles
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -4px 12px 0px #73809c1f;
  background-position: 0 40%;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  // desktop
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    width: 420px;
    border-radius: 0;
    min-height: 100%;
    position: relative;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  }
`

// on mobile, we animate the grid template rows
// so we can match the height of the content
// see https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/
const StyledDivContentContainer = styled.div<MobileDrawerStyledProps>`
  width: 100%;
  max-height: calc(100dvh - 65px);
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  justify-content: center;

  ${({ $drawerState }) => $drawerState === 'expanded' && `grid-template-rows: 1fr;`}

  // desktop
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    height: 100%;
    align-items: center;
    margin-top: 0;
  }
`

// we need to prevent the scroll bar from showing when the drawer is animating
const StyledDivContentContainerInner = styled.div<{ $isAnimating: boolean }>`
  overflow: ${(props) => (props.$isAnimating ? 'hidden' : 'auto')};
`

// Add padding to the content as a separate div to avoid the height animation
// see https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/
// the padding bottom is larger to avoid the iubenda lock icon
const StyledDivContentPadding = styled.div`
  padding: 40px 45px 64px 45px;
`

// overlay to close the drawer by clicking outside the drawer
const StyledDivOverlay = styled.div<MobileDrawerStyledProps>`
  ${({ $drawerState }) =>
    $drawerState === 'expanded'
      ? `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
      `
      : `display: none;`}

  // desktop
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    display: none;
  }
`

interface Props {
  'aria-label'?: string
  children: ReactNode
  currentStep: number
  mobileTitle: ReactNode
}

export const SignUpAside = (props: Props) => {
  const { children, mobileTitle } = props

  const contentContainerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const mobileButtonRef = useRef<HTMLButtonElement>(null)

  const [mobileDrawerState, setMobileDrawerState] = useState<MobileDrawerState>('collapsed')
  const [isAnimating, setIsAnimating] = useState(false)

  const handleToggleDrawerState = () => {
    const nextState = mobileDrawerState === 'expanded' ? 'collapsed' : 'expanded'
    setMobileDrawerState(nextState)
    setIsAnimating(true)
    // focus the correct element based on the state of the drawer
    if (nextState === 'expanded') {
      contentRef.current?.focus()
    } else {
      mobileButtonRef.current?.focus()
    }
  }

  // we need to know when the animation ends so we can change the scroll behaviour
  // it needs to be overflow: hidden during the animation
  useLayoutEffect(() => {
    const div = contentContainerRef.current

    const handleAnimationEnd = () => {
      setIsAnimating(false)
    }

    div?.addEventListener('transitionend', handleAnimationEnd)
    return () => {
      div?.removeEventListener('transitionend', handleAnimationEnd)
    }
  }, [])

  return (
    <StyledAside aria-label={props['aria-label']} id={SignUpLayoutConstant.DrawerId}>
      <StyledDivContainer>
        <SignUpAsideMobileButton
          buttonRef={mobileButtonRef}
          drawerState={mobileDrawerState}
          title={mobileTitle}
          onClick={handleToggleDrawerState}
        />

        {mobileDrawerState === 'expanded' && <StyledHr />}

        <StyledDivContentContainer
          aria-hidden={mobileDrawerState !== 'expanded'}
          $drawerState={mobileDrawerState}
          ref={contentContainerRef}
        >
          <StyledDivContentContainerInner $isAnimating={isAnimating}>
            <StyledDivContentPadding ref={contentRef} tabIndex={-1}>
              {children}
            </StyledDivContentPadding>
          </StyledDivContentContainerInner>
        </StyledDivContentContainer>
      </StyledDivContainer>

      <StyledDivOverlay $drawerState={mobileDrawerState} onClick={handleToggleDrawerState} />
    </StyledAside>
  )
}
