import { AlertNGB } from '@paddle/ant-component-library'
import { environment } from 'environment'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  LogoutWarning,
  SignupErrorMessages,
  SignupPageWarning,
  ExtendedSignUpSteps,
  ShortSignUpSteps,
} from 'components/pages/sign-up/constants'
import { SignupActions } from 'components/pages/sign-up/sign-up-form/sign-up-form-actions'
import { SignUpAccountDetails } from 'components/pages/sign-up/sign-up-steps/sign-up-account-details'
import { SignupBasicBusinessDetails } from 'components/pages/sign-up/sign-up-steps/sign-up-basic-business-details'
import { SignupIndividualPrivateBusinessDetails } from 'components/pages/sign-up/sign-up-steps/sign-up-individual-private-business-details'
import { SignupProductSelection } from 'components/pages/sign-up/sign-up-steps/sign-up-product-selection'
import { SignupPublicBusinessDetails } from 'components/pages/sign-up/sign-up-steps/sign-up-public-business-details'
import { SignUpVersionType } from 'components/pages/sign-up/types'

interface Props {
  currentStep: number
  onPageChange: (pageNumber: number) => void
  onValidate: (validationMessage: string) => void
  errorMessage: string
  disableSubmit: boolean
  isUserAuthenticationCalloutShown: boolean
  signUpVersion?: SignUpVersionType
}

const StyledAlertNGB = styled(AlertNGB)`
  margin-top: 28px;
  margin-bottom: 28px;
`

export function SignupFormElement({ currentStep, signUpVersion }: Pick<Props, 'currentStep' | 'signUpVersion'>) {
  const isExtendedSignUp = signUpVersion === 'extended'
  if (isExtendedSignUp) {
    switch (currentStep) {
      case ExtendedSignUpSteps.ProductSelection:
        return <SignupProductSelection />
      case ExtendedSignUpSteps.AccountDetails:
        return <SignUpAccountDetails isExtendedSignUp />
      case ExtendedSignUpSteps.BusinessDetails:
        return <SignupBasicBusinessDetails />
      case ExtendedSignUpSteps.IndividualPrivateBusinessDetails:
        return <SignupIndividualPrivateBusinessDetails />
      case ExtendedSignUpSteps.PublicBusinessDetails:
        return <SignupPublicBusinessDetails />
      default:
        return <SignupProductSelection />
    }
  } else {
    switch (currentStep) {
      case ShortSignUpSteps.ProductSelection:
        return <SignupProductSelection />
      case ShortSignUpSteps.AccountDetails:
        return <SignUpAccountDetails isExtendedSignUp={false} />
      default:
        return <SignupProductSelection />
    }
  }
}

const SignUpWarningCTA = () => {
  return (
    <>
      {SignupPageWarning.description} {LogoutWarning.ctaPreText}{' '}
      <Link to={environment.LOGOUT_ENDPOINT}>{LogoutWarning.linkText}</Link> {LogoutWarning.ctaPostText}
    </>
  )
}

export function SignupForm({
  currentStep,
  onPageChange,
  errorMessage,
  onValidate,
  disableSubmit,
  isUserAuthenticationCalloutShown,
  signUpVersion,
}: Props) {
  const [warningType, setWarningType] = useState<'error' | 'warning'>('warning')
  const { errors, dirty, touched, handleSubmit } = useFormikContext()
  const isFormValuesValidAndComlete = !Object.keys(errors).length && Object.keys(touched).length > 0 && dirty

  const handleFormSubmit = async () => {
    if (!isUserAuthenticationCalloutShown && isFormValuesValidAndComlete) {
      handleSubmit()
    } else if (isUserAuthenticationCalloutShown && isFormValuesValidAndComlete) {
      setWarningType('error')
    }
  }
  return (
    <>
      {isUserAuthenticationCalloutShown && (
        <StyledAlertNGB
          aria-label={SignupPageWarning.label}
          description={<SignUpWarningCTA />}
          type={warningType}
          showIcon
        />
      )}
      {errorMessage && (
        <StyledAlertNGB
          aria-label={SignupErrorMessages.FormValidationMessageLabel}
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <SignupFormElement currentStep={currentStep} signUpVersion={signUpVersion} />
      <SignupActions
        onPageChange={onPageChange}
        currentStep={currentStep}
        onValidate={onValidate}
        disableSubmit={disableSubmit}
        signUpVersion={signUpVersion}
        onSubmit={handleFormSubmit}
      />
    </>
  )
}
