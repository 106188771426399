export const TwoFAConstants = {
  Title: 'Two-factor authentication',
  Subtitle:
    'Open the two-factor authentication app on your device to view your authentication code and verify your identity.',
  EnterCode: 'Enter your 6-digit code',
  CTA: 'Verify',
  CannotAccess: 'Can’t access your two-factor device?',
  RecoverAccount: 'Recover my account',
  RequiredError: 'Enter your authentication code',
  RecoveryCodeLength: 'Your authentication code must be exactly 6 digits',
}
