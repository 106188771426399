import { Button } from '@paddle/ant-component-library'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  SignupActionConstants,
  SignupErrorMessages,
  SignupFieldNames,
  SignupFormBusinessTypeValues,
  ExtendedSignUpSteps,
  UnSupportedProductsList,
  ShortSignUpSteps,
} from 'components/pages/sign-up/constants'
import { SignUpLayoutStyledConstant } from 'components/pages/sign-up/sign-up-page-layout/constants'
import { SignupFormValues, SignUpVersionType } from 'components/pages/sign-up/types'

interface Props {
  currentStep: number
  onPageChange: (pageNumber: number) => void
  onValidate: (validationMessage: string) => void
  disableSubmit: boolean
  signUpVersion?: SignUpVersionType
  onSubmit: () => void
}

const StyledActionContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 100px;
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    margin: 40px 0px;
  }
`
const BusinessDetailsNextStep: Record<string, number> = {
  [SignupFormBusinessTypeValues.Individual]: ExtendedSignUpSteps.IndividualPrivateBusinessDetails,
  [SignupFormBusinessTypeValues.Private]: ExtendedSignUpSteps.IndividualPrivateBusinessDetails,
  [SignupFormBusinessTypeValues.Public]: ExtendedSignUpSteps.PublicBusinessDetails,
}

const ShortFieldsToBeValidToProceed: Record<number, string[]> = {
  [ShortSignUpSteps.ProductSelection]: [SignupFieldNames.Products, SignupFieldNames.TermsAndConditions],
  [ShortSignUpSteps.AccountDetails]: [
    SignupFieldNames.FirstName,
    SignupFieldNames.LastName,
    SignupFieldNames.Email,
    SignupFieldNames.Password,
    SignupFieldNames.Website,
  ],
}

const ExtendedFieldsToBeValidToProceed: Record<number, string[]> = {
  [ExtendedSignUpSteps.ProductSelection]: [SignupFieldNames.Products, SignupFieldNames.TermsAndConditions],
  [ExtendedSignUpSteps.AccountDetails]: [
    SignupFieldNames.FirstName,
    SignupFieldNames.LastName,
    SignupFieldNames.Email,
    SignupFieldNames.Password,
  ],
  [ExtendedSignUpSteps.BusinessDetails]: [
    SignupFieldNames.BusinessName,
    SignupFieldNames.BusinessType,
    SignupFieldNames.AnnualRevenue,
    SignupFieldNames.Website,
  ],
  [ExtendedSignUpSteps.IndividualPrivateBusinessDetails]: [
    SignupFieldNames.Country,
    SignupFieldNames.StreetAddress,
    SignupFieldNames.Postcode,
    SignupFieldNames.City,
  ],
  [ExtendedSignUpSteps.PublicBusinessDetails]: [SignupFieldNames.Country, SignupFieldNames.Stock],
}

function areAllProductsUnsupported(values: SignupFormValues): boolean {
  return values.products.length > 0 && values.products.every((product) => UnSupportedProductsList.includes(product))
}

function getNavigationPaths(currentStep: number, signUpVersion: 'extended' | 'short', values: SignupFormValues) {
  const isExtendedSignUp = signUpVersion === 'extended'
  if (isExtendedSignUp) {
    switch (currentStep) {
      case ExtendedSignUpSteps.ProductSelection:
        return { nextStep: areAllProductsUnsupported(values) ? null : ExtendedSignUpSteps.AccountDetails }

      case ExtendedSignUpSteps.AccountDetails:
        return { nextStep: ExtendedSignUpSteps.BusinessDetails }

      case ExtendedSignUpSteps.BusinessDetails:
        return {
          nextStep:
            BusinessDetailsNextStep[values.businessType] || ExtendedSignUpSteps.IndividualPrivateBusinessDetails,
        }
      case ExtendedSignUpSteps.IndividualPrivateBusinessDetails:
        return { nextStep: ExtendedSignUpSteps.Submit }

      case ExtendedSignUpSteps.PublicBusinessDetails:
        return { nextStep: ExtendedSignUpSteps.Submit }

      default:
        return { nextStep: ExtendedSignUpSteps.Submit }
    }
  } else {
    switch (currentStep) {
      case ShortSignUpSteps.ProductSelection:
        return { nextStep: areAllProductsUnsupported(values) ? null : ShortSignUpSteps.AccountDetails }

      case ShortSignUpSteps.AccountDetails:
        return { nextStep: ShortSignUpSteps.Submit, nextStepText: SignupActionConstants.CreateAccount }

      default:
        return { nextStep: null }
    }
  }
}

const CustomFormValidationMessages = [SignupErrorMessages.ProductSelectionErrorMessage]

export function SignupActions({
  currentStep,
  onPageChange,
  signUpVersion = 'extended',
  onValidate,
  disableSubmit,
  onSubmit,
}: Props) {
  const isExtendedSignUp = signUpVersion === 'extended'

  const { isSubmitting, values, validateForm, setFieldTouched } = useFormikContext<SignupFormValues>()
  const { nextStep, nextStepText } = getNavigationPaths(currentStep, signUpVersion, values)

  const FieldsToBeValidToProceed = isExtendedSignUp ? ExtendedFieldsToBeValidToProceed : ShortFieldsToBeValidToProceed

  const handleNextStep = async () => {
    onValidate('')
    if (nextStep) {
      const validationErrors = await validateForm()
      if (
        Object.keys(validationErrors).some((fieldName) => FieldsToBeValidToProceed[currentStep].includes(fieldName))
      ) {
        FieldsToBeValidToProceed[currentStep].map((fieldName: string) => setFieldTouched(fieldName, true))
        if (
          (isExtendedSignUp && currentStep === ExtendedSignUpSteps.ProductSelection) ||
          (!isExtendedSignUp && currentStep === ShortSignUpSteps.ProductSelection)
        ) {
          onValidate(CustomFormValidationMessages[currentStep && 0] || SignupErrorMessages.FormValidationMessage)
        }
      } else if (
        (nextStep === ExtendedSignUpSteps.Submit && isExtendedSignUp) ||
        (nextStep === ShortSignUpSteps.Submit && !isExtendedSignUp)
      ) {
        onSubmit()
      } else {
        onPageChange(nextStep)
      }
    }
  }
  return (
    <StyledActionContainer>
      {nextStep ? (
        <Button type="primary" htmlType="submit" disabled={isSubmitting || disableSubmit} onClick={handleNextStep}>
          {nextStepText ?? SignupActionConstants.Continue}
        </Button>
      ) : null}
    </StyledActionContainer>
  )
}
