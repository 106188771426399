import { colors, FormItem, Input } from '@paddle/ant-component-library'
import { FieldProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { InputFieldProps, LayoutType } from '../form'

const StyledInputPassword = styled(Input.Password)`
  height: 40px;
  &::placeholder {
    color: ${colors.padBlue[400]};
  }
  .ant-input-password-icon {
    color: ${colors.elecBlue[500]};
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
  }

  &&& {
    .ant-input {
      box-shadow: none !important;
    }
  }
`

const verticalStyle = { flexDirection: 'column' }

export const PasswordInputFieldToggle = {
  Show: 'Show',
  Hide: 'Hide',
} as const

export const PasswordInputField: React.FunctionComponent<FieldProps<any> & InputFieldProps> = ({
  field, // name, value, onBlur etc
  form: { getFieldMeta },
  ...props
}) => {
  const {
    'aria-label': ariaLabel,
    layout,
    disabled,
    id,
    label,
    required,
    colon,
    layoutType = LayoutType.Horizontal,
    placeholder,
    maxLength,
    inputStyle = {},
    hideErrorMessage = false,
    autoFocus = false,
    autoComplete,
    extra,
  } = props

  const meta = getFieldMeta(field.name)
  const errorMessage = meta.touched && meta.error

  return (
    <FormItem
      label={label}
      htmlFor={id}
      {...layout}
      style={layoutType === LayoutType.Vertical ? verticalStyle : {}}
      required={required}
      help={!hideErrorMessage && errorMessage ? errorMessage : undefined}
      colon={colon}
      type={'text'}
      validateStatus={errorMessage ? 'error' : undefined}
      extra={errorMessage ? '' : extra}
    >
      <StyledInputPassword
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        {...field}
        placeholder={placeholder}
        maxLength={maxLength}
        style={inputStyle}
        autoFocus={autoFocus}
        autoComplete={autoComplete === false ? 'off' : undefined}
        iconRender={(visible: boolean) => (visible ? PasswordInputFieldToggle.Hide : PasswordInputFieldToggle.Show)}
      />
    </FormItem>
  )
}
