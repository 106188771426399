export const ErrorImage = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="34" stroke="#73809C" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 20C33.8954 20 33 20.8954 33 22V38C33 39.1046 33.8954 40 35 40C36.1046 40 37 39.1046 37 38V22C37 20.8954 36.1046 20 35 20ZM35 50C36.6569 50 38 48.6569 38 47C38 45.3431 36.6569 44 35 44C33.3431 44 32 45.3431 32 47C32 48.6569 33.3431 50 35 50Z"
        fill="#73809C"
      />
    </svg>
  )
}
