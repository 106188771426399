import { Typography, colors } from '@paddle/ant-component-library'
import { environment } from 'environment'
import styled from 'styled-components'

import { routes } from '../../../constants/routes'
import { SMALL_BREAKPOINT_PX } from '../helpers'
import { RouterLink } from './router-link'

export enum Constants {
  Account = "Don't have an account?",
  Signup = 'Sign up',
  RememberedPassword = 'Remembered your password?',
  Login = 'Login',
  AlreadyHaveAnAccount = 'Already have an account?',
}

const Container = styled.div<Props>`
  display: ${({ inInfoPage }) => (inInfoPage ? 'block' : 'none')};

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    display: ${({ inInfoPage }) => (!!inInfoPage ? 'none' : 'block')};
  }
`

const StyledRouterLink = styled(RouterLink)`
  margin-left: 6px;
  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled.a`
  margin-left: 6px;
  &:hover {
    text-decoration: underline;
  }
`

const Message = styled(Typography.Text)`
  color: ${colors.uiWhite};

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    color: ${colors.padBlue[600]};
  }
`

interface Props {
  inInfoPage?: boolean
  existingAccount?: boolean
}

export const SignUp = ({ inInfoPage = false }: Props) => (
  <Container inInfoPage={inInfoPage}>
    <Message>{Constants.Account}</Message>
    <StyledLink href={environment.SIGNUP_ENDPOINT}>{Constants.Signup}</StyledLink>
  </Container>
)

export const Login = ({ inInfoPage = false, existingAccount = false }: Props) => (
  <Container inInfoPage={inInfoPage}>
    <Message>{existingAccount ? Constants.AlreadyHaveAnAccount : Constants.RememberedPassword}</Message>
    <StyledRouterLink to={routes.login}>{Constants.Login}</StyledRouterLink>
  </Container>
)
