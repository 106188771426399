import { List, Typography, colors } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { BenefitsList } from 'components/pages/sign-up/constants'

const StyledDiv = styled.div`
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    color: ${colors.uiWhite};
    margin: 0px;
  }
`

const StyledTitle = styled(Typography.Title)`
  && {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 40px;
    color: ${colors.uiWhite};
  }
`

const StyledText = styled(Typography.Text)`
  && {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-top: 40px;
    color: ${colors.uiWhite};
  }
`

const StyledImg = styled.img`
  background-color: #fddd35;
  padding: 8px;
  border-radius: 4px;
`

export const MoRBenefits = () => {
  return (
    <StyledDiv>
      <StyledTitle level={4}>
        Take the headache out of growing your software business with a Merchant of Record
      </StyledTitle>
      <List
        split={false}
        itemLayout="horizontal"
        dataSource={BenefitsList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={<StyledImg src={item.logo} />} title={item.title} />
          </List.Item>
        )}
      />
      <StyledText>+ many more benefits when partnering with Paddle</StyledText>
    </StyledDiv>
  )
}
