import { AxiosResponse } from 'axios'

import { apiClient } from 'modules/api/client'
import { identityServicePaths } from 'modules/api/constants'

interface ConsentInfoResponse {
  data: {
    redirect_to?: string
    client?: string
    scopes?: string[]
  }
}

export const consentInfo = async (challenge: string): Promise<AxiosResponse<ConsentInfoResponse>> => {
  return apiClient.get(identityServicePaths.consentPath, {
    params: {
      challenge,
    },
    withCredentials: true,
  })
}

export const submitConsent = async (scopes: string[], challenge: string) =>
  apiClient.post(
    identityServicePaths.consentPath,
    {
      scopes,
      challenge,
    },
    { withCredentials: true },
  )
