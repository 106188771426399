import { Typography } from '@paddle/ant-component-library'
import { environment } from 'environment'
import { useLocation } from 'react-router-dom'

import { MainContent, Container, Title, Login } from '../../shared/components'
import { ResetPasswordConstants } from './constants'
import { LocationState } from './types'

export const ResetPasswordRequiredPage = () => {
  const location = useLocation()
  const { email } = (location as LocationState)?.state || {}
  const { reason } = (location as LocationState)?.state || {}

  const resetLink = environment.RESET_ENDPOINT + '?email=' + email

  const reasonText = () => {
    switch (reason) {
      case 1:
      case 2:
      case 100:
        return (
          <Typography.Text>
            <p>Your password has expired and must now be changed.</p>
          </Typography.Text>
        )
      case 3:
        return (
          <Typography.Text>
            <p>
              An admin user on your Paddle vendor account has marked your password has expired and it must now be
              changed.
            </p>
          </Typography.Text>
        )
      case 4:
        return (
          <Typography.Text>
            <p>
              The password you have set for your Paddle account is known to be present in a list of publicly shared
              compromised credentials. As such, we require that it is changed. If you&apos;d like to learn more about
              this then please get in touch with <a href="https://www.paddle.com/help/start">Paddle support</a>.
            </p>
          </Typography.Text>
        )
      default:
        return null
    }
  }

  return (
    <MainContent>
      <Container>
        <Title>{ResetPasswordConstants.Title}</Title>
        {reasonText()}
        <Typography.Text>
          <a href={resetLink}>{ResetPasswordConstants.Action}</a>
        </Typography.Text>
      </Container>

      <Login />
    </MainContent>
  )
}
