import { Button, Typography } from '@paddle/ant-component-library'
import { Field, Formik } from 'formik'
import styled from 'styled-components'
import { object, string } from 'yup'

import { MainContent, Container, Title, Form, FormRow, InputField, Login } from '../../shared/components'
import { ResetPasswordConstants } from './constants'

const StyledCallToAction = styled(Button)`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 40px;
  height: 40px;
`

const initialValues = { email: '' }

const validationSchema = object().shape({
  email: string().trim().email(ResetPasswordConstants.EmailError).required(ResetPasswordConstants.RequiredError),
})

export const ResetPasswordPage = () => {
  return (
    <MainContent>
      <Container>
        <Title>{ResetPasswordConstants.Title}</Title>
        <Typography.Text>{ResetPasswordConstants.Subtitle}</Typography.Text>

        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(_, { setSubmitting }) => {
            setSubmitting(false)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  name="email"
                  id="email-input"
                  disabled={isSubmitting}
                  required={true}
                  component={InputField}
                  placeholder={ResetPasswordConstants.Placeholder}
                />
              </FormRow>
              <StyledCallToAction type="primary" htmlType="submit" disabled={isSubmitting}>
                {ResetPasswordConstants.CTA}
              </StyledCallToAction>
            </Form>
          )}
        </Formik>
      </Container>

      <Login />
    </MainContent>
  )
}
