import { Col, Row } from '@paddle/ant-component-library'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

import { SignupFieldLabels, SignupFieldNames, SignupFormLayout } from 'components/pages/sign-up/constants'
import { CountriesOptions } from 'components/pages/sign-up/countries'
import { SignupFormValues } from 'components/pages/sign-up/types'
import { FormSelect, InputField, LayoutType } from 'components/shared/components'

const StyledDiv = styled.div`
  margin-top: 40px;
`

export function SignupIndividualPrivateBusinessDetails() {
  const { isSubmitting } = useFormikContext<SignupFormValues>()

  return (
    <StyledDiv>
      <Row gutter={[20, 40]}>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Country}
            id={SignupFieldNames.Country}
            disabled={isSubmitting}
            required={true}
            component={FormSelect}
            label={SignupFieldLabels.CountryCopy2}
            options={CountriesOptions}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.StreetAddress}
            id={SignupFieldNames.StreetAddress}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.AddressLine1}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Apartment}
            id={SignupFieldNames.Apartment}
            colon={false}
            disabled={isSubmitting}
            component={InputField}
            label={SignupFieldLabels.AddressLine2}
          />
        </Col>
        <Col span={12}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.City}
            id={SignupFieldNames.City}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.City}
          />
        </Col>
        <Col span={12}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Postcode}
            id={SignupFieldNames.Postcode}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.PostalZipCode}
          />
        </Col>
      </Row>
    </StyledDiv>
  )
}
