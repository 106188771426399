import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'

export interface ApiErrorResponse {
  error: ApiErrorItem
}

interface ApiErrorItem {
  code: string
  status: string
  type: string
}

export const useGetFirstApiError = () => {
  const [error, setError] = useState<ApiErrorItem>()

  const onErrorCallback = useCallback((error: AxiosError<ApiErrorResponse>) => {
    const firstError = error?.response?.data?.error
    if (firstError) {
      setError(firstError)
    } else {
      setError({
        code: error.message,
        status: '',
        type: '',
      })
    }
  }, [])

  const removeErrorCallback = useCallback(() => setError(undefined), [])

  return {
    error,
    setError,
    onErrorCallback,
    removeErrorCallback,
  }
}
