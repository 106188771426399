import { environment } from 'environment'
import { isConsentGiven } from 'libraries/iubenda'

export const hockeystackTrackingExist = () => !!document.getElementById('wphs')

export const injectHockeystack = () => {
  const hasGivenConsent = isConsentGiven()
  if (!hasGivenConsent || !environment.HOCKEYSTACK_TRACKING_ID) return false
  if (!hockeystackTrackingExist()) {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.dataset.apikey = environment.HOCKEYSTACK_TRACKING_ID
    script.dataset.cookieless = '1'
    script.dataset.autoIdentify = '1'
    script.id = 'wphs'
    script.src = `//cdn.jsdelivr.net/npm/hockeystack@latest/hockeystack.min.js`
    document.head.appendChild(script)
    return true
  }
  return false
}
