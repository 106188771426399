export const LoginConstants = {
  Title: 'Login',
  EmailAddress: 'Email address',
  Password: 'Password',
  CTA: 'Login',
  ForgotPassword: 'Forgot your password?',
  RememberMe: 'Remember me',
  EmailError: 'Please input a valid email address',
  EmailRequiredError: 'Enter your email address',
  PasswordRequiredError: 'Enter your password',
} as const
