import styled from 'styled-components'

import { SMALL_BREAKPOINT_PX } from '../helpers'

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  justify-content: space-between;
`

export const Container = styled.div`
  width: 400px;
  margin: 0 auto;
  margin-top: 262px;

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    max-width: 400px;
    width: 100%;
    margin-top: 0px;
  }
`
