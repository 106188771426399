import { colors, Typography } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { ConsultingServicesIcon } from 'components/pages/sign-up/icons/consulting-services-icon'
import { DigitalProductsIcon } from 'components/pages/sign-up/icons/digital-products-icon'
import { FinancialServicesIcon } from 'components/pages/sign-up/icons/financial-services-icon'
import { GamblingProductsIcon } from 'components/pages/sign-up/icons/gambling-products-icon'
import { MarketplaceIcon } from 'components/pages/sign-up/icons/marketplace-icon'
import { PhysicalProductsIcon } from 'components/pages/sign-up/icons/physical-products-icon'

export const StyledTooltipText = styled(Typography.Text)`
  && {
    color: ${colors.uiWhite};
    display: block;
    li {
      list-style-type: initial;
    }
  }
`

interface StepsMetadata {
  title: string
  subtitle?: string
}

export const SignupFormLayout = {
  labelAlign: 'left' as const,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
} as const

export const SignupFormBusinessTypeValues = {
  Individual: 'individual',
  Private: 'private',
  Public: 'public',
} as const

export const SignupFormBusinessTypeOptions = [
  { label: 'Individual', value: SignupFormBusinessTypeValues.Individual },
  { label: 'Private', value: SignupFormBusinessTypeValues.Private },
  { label: 'Public', value: SignupFormBusinessTypeValues.Public },
] as const

export const SignupFormAverageRevenueValues = {
  notYetLive: 'Not yet live',
  lessThan100K: '$0 - $100K',
  lessThan500K: '$100K - $500K',
  lessThan3M: '$500K - $3M',
  lessThan10M: '$3M - $10M',
  moreThan10M: '$10M+',
} as const

export const SignupFormAverageRevenueOptions = [
  { label: SignupFormAverageRevenueValues.notYetLive, value: SignupFormAverageRevenueValues.notYetLive },
  { label: SignupFormAverageRevenueValues.lessThan100K, value: SignupFormAverageRevenueValues.lessThan100K },
  { label: SignupFormAverageRevenueValues.lessThan500K, value: SignupFormAverageRevenueValues.lessThan500K },
  { label: SignupFormAverageRevenueValues.lessThan3M, value: SignupFormAverageRevenueValues.lessThan3M },
  { label: SignupFormAverageRevenueValues.lessThan10M, value: SignupFormAverageRevenueValues.lessThan10M },
  { label: SignupFormAverageRevenueValues.moreThan10M, value: SignupFormAverageRevenueValues.moreThan10M },
] as const

export const SignupWebsitePrefix = 'https://'

export const SignupPageWarning = {
  label: `Signup warning`,
  description: `It looks like you're already logged into the dashboard.`,
}

export const LogoutWarning = {
  ctaPreText: 'Please make sure to',
  linkText: 'logout',
  ctaPostText: 'before proceeding further.',
}

export const SignUpPhase2Constant = {
  PageTitle: 'Paddle sign up',
  TestimonialAriaLabel: 'Testimonial',
} as const

export const ExtendedSignUpSteps = {
  ProductSelection: 0,
  AccountDetails: 1,
  BusinessDetails: 2,
  IndividualPrivateBusinessDetails: 3,
  PublicBusinessDetails: 4,
  Submit: 5,
} as const

export const ShortSignUpSteps = {
  ProductSelection: 0,
  AccountDetails: 1,
  Submit: 2,
} as const

export const SignupFieldHelpText = {
  PasswordHelpText: 'Minimum 8 characters, with uppercase, lowercase, number or a symbol',
  StockTicker: 'If you are a subsidiary of another company, please provide the stock sticker of your parent company.',
  StockPlaceholder: 'eg. AAPL',
  WebsitePlaceholder: 'e.g. www.mywebsite.com',
  OptionalFieldText: "Leave this blank if you don't have one yet",
} as const

export const SignUpStepsMetadata: Record<'extended' | 'short', StepsMetadata[]> = {
  extended: [
    {
      title: 'What do you sell?',
      subtitle:
        'Paddle exclusively supports the sales of SaaS and digital products. If you sell other types of products, we may not be the right fit for you.',
    },
    {
      title: 'Account details',
    },
    {
      title: 'Business details',
      subtitle: 'Part 1 of 2',
    },
    {
      title: 'Business details',
      subtitle: 'Part 2 of 2',
    },
    {
      title: 'Business details',
      subtitle: 'Part 2 of 2',
    },
  ],
  short: [
    {
      title: 'What do you sell?',
      subtitle:
        'Paddle exclusively supports the sales of SaaS and digital products. If you sell other types of products, we may not be the right fit for you.',
    },
    {
      title: 'Account details',
    },
  ],
}

export const UnsupportedAndRestrictedProductsList: string[] = [
  'physical_products',
  'gambling_products',
  'cloud_hosting',
  'human_services',
  'financial_services',
  'marketplace_for_sellers',
]

export const UnSupportedProductsList = [
  'financial_services',
  'gambling_products',
  'physical_products',
  'marketplace_for_sellers',
]
export const RestrictedProductsList = ['cloud_hosting', 'human_services']

export const ProductSelectionList = [
  {
    title: 'Digital products or SaaS',
    id: 'digital_products_or_saas',
    supported: true,
    tooltip:
      "Select if you sell software, online courses, or other digital products that don't require physical delivery.",
  },
  {
    title: 'Mobile apps',
    id: 'apps',
    supported: true,
    tooltip:
      'Select if you sell mobile apps and upgrades to freemium products or sell digital items or goods as part of your mobile app experience.',
  },
  {
    title: 'Human services',
    supported: false,
    id: 'human_services',
    tooltip:
      'Select if you exclusively offer consulting or advisory services that are not related to a software offering. For example, you only offer legal advice or coaching.',
  },
  {
    title: 'Cloud hosting',
    id: 'cloud_hosting',
    supported: false,
    tooltip: 'Select if you sell services to store or host on behalf of others.',
  },

  {
    title: 'Financial services',
    id: 'financial_services',
    supported: false,
    tooltip:
      'Select if you provide financial advice or money transfer services, such as mortgage advice, investments, trading signals.',
  },
  {
    title: 'Physical products ',
    id: 'physical_products',
    supported: false,
    tooltip: 'Select if you sell products that require physical delivery.',
  },
  {
    title: 'Gambling products',
    id: 'gambling_products',
    supported: false,
    tooltip: (
      <StyledTooltipText>
        <StyledTooltipText>Select if you sell any of the following:</StyledTooltipText>
        <ul>
          <li>Betting related products</li>
          <li>Lotteries, auctions, contests, sweepstakes or games of chance</li>
          <li>Sports forecasting or odds making</li>
          <li>Fantasy sports leagues</li>
        </ul>
      </StyledTooltipText>
    ),
  },
  {
    title: 'Marketplace for sellers',
    id: 'marketplace_for_sellers',
    supported: false,
    tooltip: 'Select if you provide a marketplace for other merchants to sell products and services to customers.',
  },
]

export const BenefitsList = [
  {
    logo: '/images/mor-benefits/benefit-one.svg',
    title: 'Maximize payment performance',
  },
  {
    logo: '/images/mor-benefits/benefit-two.svg',
    title: 'Global sales tax compliance',
  },
  {
    logo: '/images/mor-benefits/benefit-three.svg',
    title: 'Automatically reduce churn',
  },
]

export const SignupFieldLabels = {
  Products: 'Select products',
  FirstName: 'First name',
  LastName: 'Last name',
  Name: 'Full name',
  EmailAddress: 'Business email address',
  PrimaryEmailAddress: 'Email address',
  EmailOnlyText: 'Email',
  Country: 'Country',
  CountryCopy2: 'Where is your business based?',
  Website: 'Website address',
  Password: 'Choose a password',
  CreatePassword: 'Create password',
  PasswordOnlyText: 'Password',
  MarketingConsent: 'I want to receive updates from Paddle about relevant products or services',
  BusinessName: 'Business name',
  BusinessType: 'Business type',
  AnnualRevenue: "What is your company's annual revenue in USD?",
  AnnualRevenueCopy2: "What's your annual revenue?",
  HasPaddleAccount: 'I have another Paddle account',
  ExistingVendorId: 'Existing vendor ID',
  RegistrationNumber: 'Business registration number',
  AddressTitle: 'Please provide your business address',
  StreetAddress: 'Street address',
  AddressLine1: 'Street address line 1',
  AddressLine2: 'Street address line 2',
  Apartment: 'Apt, suite, etc',
  City: 'City/town',
  Postcode: 'Postcode',
  PostalZipCode: 'Postal / zip code',
  Stock: 'Stock ticker',
} as const

export const SignupFieldNames = {
  SignUpVersion: 'signUpVersion',
  Products: 'products',
  TermsAndConditions: 'acceptedTermsOfService',
  FirstName: 'firstName',
  LastName: 'lastName',
  Email: 'email',
  Password: 'password',
  MarketingConsent: 'marketingConsent',
  BusinessName: 'businessName',
  BusinessType: 'businessType',
  AnnualRevenue: 'annualRevenue',
  HasPaddleAccount: 'hasPaddleAccount',
  ExistingVendorId: 'existingVendorId',
  Website: 'website',
  RegistrationNumber: 'registrationNumber',
  StreetAddress: 'streetAddress',
  Apartment: 'apartment',
  City: 'city',
  Postcode: 'postcode',
  Country: 'country',
  Stock: 'stock',
} as const

export const SignupErrorMessages = {
  CreationFailureMessage: 'There was an error creating your account.',
  FormValidationMessageLabel: 'Form Validation error message',
  FormValidationMessage: 'One or more fields are missing or invalid',
  ProductSelectionErrorMessage:
    'You need to select which types of products you sell and agree to our Terms of Use & Privacy Policy in order to continue.',
  CreationFailureDuplicateMessageSuffix: 'already exists',
  [SignupFieldLabels.EmailAddress]:
    "We couldn't process your request. The email address you entered may be invalid or already associated with an existing account.",
  [SignupFieldLabels.BusinessName]:
    "We couldn't process your request. The business name you entered may be invalid or already associated with an existing account.",
  [`${SignupFieldLabels.BusinessName}, ${SignupFieldLabels.EmailAddress}`]:
    "We couldn't process your request. The business name and email address you entered may be invalid or already associated with an existing account.",
  CreationFailureDuplicateBusinessEmail:
    "We couldn't process your request. The email address you entered may be invalid or already associated with an existing account.",
} as const

export const SignupActionConstants = {
  GetStarted: 'Get started',
  Continue: 'Continue',
  Back: 'Back',
  Submit: 'submit',
  CreateAccount: 'Create account',
} as const

export const SignupProductSelectionConstants = {
  FooterText: 'I have read and understood Paddle’s',
  AUPPolicy: 'Acceptable use policy',
  Terms: 'Terms of use',
  PrivacyPolicy: 'Privacy policy',
  TermsLink: 'https://www.paddle.com/legal/terms',
  AUPPolicyLink: 'https://www.paddle.com/support/aup',
  PrivacyPolicyLink: 'https://www.paddle.com/legal/privacy',
  ValidationAlertLabel: 'Product selection error',
  SomeProductsAreUnsupported: "Some of your products can't be sold through Paddle. Are you sure you want to continue?",
  AllProductsAreUnsupported: "Unfortunately, we don't support the types of products you sell.",
  Products: [
    {
      title: 'Digital products or SaaS',
      id: 'saas',
      icon: <DigitalProductsIcon />,
      tooltip:
        "Select if you sell software, online courses, iOS apps, or other digital products that don't require physical delivery.",
    },
    {
      title: 'Physical products ',
      id: 'physical',
      icon: <PhysicalProductsIcon />,
      tooltip: 'Select if you sell products that require physical delivery.',
    },
    {
      title: 'Gambling products',
      id: 'gambling',
      icon: <GamblingProductsIcon />,
      tooltip: (
        <StyledTooltipText>
          <StyledTooltipText>Select if you sell any of the following:</StyledTooltipText>
          <ul>
            <li>Betting related products</li>
            <li>Lotteries, auctions, contests, sweepstakes or games of chance</li>
            <li>Sports forecasting or odds making</li>
            <li>Fantasy sports leagues</li>
          </ul>
        </StyledTooltipText>
      ),
    },
    {
      title: 'Consulting services',
      id: 'consulting',
      icon: <ConsultingServicesIcon />,
      tooltip:
        'Select if you exclusively offer consulting or advisory services that are not related to a software offering. For example, you only offer legal advice or coaching.',
    },
    {
      title: 'Financial services',
      id: 'financial',
      icon: <FinancialServicesIcon />,
      tooltip:
        'Select if you provide financial advice or money transfer services, such as mortgage advice, investments, trading signals.',
    },
    {
      title: 'Marketplace for sellers',
      id: 'marketplace',
      icon: <MarketplaceIcon />,
      tooltip: 'Select if you provide a marketplace for other merchants to sell products and services to customers.',
    },
  ],
} as const

export const SignupErrorFields: Record<string, string> = {
  InvalidField: 'invalid_field',
  InternalError: 'internal_error',
  Email: 'user.email',
  BusinessName: 'business.name',
}

export const SignupErrorFieldLabels = {
  [SignupErrorFields.Email]: SignupFieldLabels.EmailAddress,
  [SignupErrorFields.BusinessName]: SignupFieldLabels.BusinessName,
} as const

export const correlationIDText = 'Correlation ID:'
