import { Button } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { RouterLink } from 'components/shared/components'

export const StyledCallToAction = styled(Button)`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 40px;
  height: 40px;
`

export const StyledRouterLink = styled(RouterLink)`
  margin-left: 6px;
`
