import { useMutation } from '@tanstack/react-query'
import { environment } from 'environment'
import { safeRedirect } from 'libraries/redirect'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'

import { routes } from 'constants/routes'
import { COOKIE_REDIRECT_LOCATION_KEY } from 'constants/shared'
import { useGetFirstApiError } from 'modules/api/hooks'
import { doLogin, LoginValues } from 'modules/api/login'

import { LocationState } from './types'

export const useOnSubmit = () => {
  const { error, onErrorCallback, removeErrorCallback } = useGetFirstApiError()
  const navigate = useNavigate()
  const location = useLocation()
  const [cookies, , removeCookie] = useCookies([COOKIE_REDIRECT_LOCATION_KEY])
  const { email, password, remember } = (location as LocationState)?.state || {}
  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationFn: (values: Pick<LoginValues, 'recoveryCode'>) => {
      removeErrorCallback()
      return doLogin({ email, password, recoveryCode: values.recoveryCode, remember })
    },

    onError: onErrorCallback,
    onSuccess: () => {
      let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY] ?? environment.FALLBACK_REDIRECT_URL
      removeCookie(COOKIE_REDIRECT_LOCATION_KEY)
      safeRedirect(redirectLocation, navigate)
    },
  })

  useEffect(() => {
    if (!email || !password) {
      return navigate(routes.login)
    }
  }, [email, password, navigate])

  return {
    onSubmit: mutate,
    isError,
    isSuccess,
    isPending,
    errorCode: error?.code,
  }
}
