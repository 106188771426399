import { Button, colors } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { GenericErrorBoundaryConstant } from 'components/pages/generic-error-boundary/constants'
import { ErrorImage } from 'components/pages/generic-error-boundary/error-image'
import { Container, MainContent } from 'components/shared/components'

const StyledDivBG = styled.div`
  height: 100vh;
  background-color: ${colors.skyBlue[100]};
`

const StyledDivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  background-color: transparent;
`

const StyledHeadingContainer = styled.h4`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.padBlue[600]};
  margin: 30px 0 10px 0;
`

const StyledDivSubheadingContainer = styled.div`
  font-size: 14px;
  color: ${colors.padBlue[600]};
`

const StyledDivErrorContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 16px;
  font-size: 14px;
  color: ${colors.uiRed[500]};
`

interface Props {
  errorMessage?: string
  resetError?: () => void
}

export const GenericErrorBoundary = (props: Props) => {
  const { errorMessage, resetError } = props

  return (
    <StyledDivBG>
      <MainContent data-testid={'generic-error-boundary'}>
        <Container>
          <StyledDivContainer>
            <ErrorImage />
            <StyledHeadingContainer>{GenericErrorBoundaryConstant.Heading}</StyledHeadingContainer>
            <StyledDivSubheadingContainer>{GenericErrorBoundaryConstant.Subheading}</StyledDivSubheadingContainer>
            {errorMessage ? <StyledDivErrorContainer>{errorMessage}</StyledDivErrorContainer> : null}
            {resetError ? (
              <div>
                <Button onClick={resetError}>{GenericErrorBoundaryConstant.ResetButtonLabel}</Button>
              </div>
            ) : null}
          </StyledDivContainer>
        </Container>
      </MainContent>
    </StyledDivBG>
  )
}
