import { colors, ExclamationCircleOutlined, Typography } from '@paddle/ant-component-library'
import styled from 'styled-components'

// TODO add more errors and use the component in all pages
const GenericError = 'Generic Error'
const errorMessages = new Map([
  ['forbidden.invalid_credentials', 'Incorrect login details'],
  ['too_many_requests.rate_limited', 'Too many incorrect login attempts, please wait before trying again.'],
  ['Network Error', 'The network connection was lost, please check your connection and try again.'],
  [GenericError, 'Something went wrong, please try again.'],
  [
    'forbidden.employee_auth_id_token_mismatch',
    'You have attempted to login with a username that does not match your employee identity. Please make sure you are using the correct account and try again.',
  ],
  [
    'forbidden.user_deactivated',
    'Your user has been deactivated, please contact your account administrator for further assistance.',
  ],
])

const getErrorMessage = (errorMessage: string) => {
  if (errorMessages.has(errorMessage)) {
    return errorMessages.get(errorMessage)
  }

  if (errorMessage.includes('.employee_auth_')) {
    return errorMessages.get(GenericError) + ` (Code: ${errorMessage})`
  }

  return errorMessages.get(GenericError)
}

const Icon = styled(ExclamationCircleOutlined)`
  && {
    color: ${colors.uiRed[400]};
    font-size: 20px;
  }
`

const ErrorMessageContainer = styled.div`
  display: flex;
  gap: 12px;
  border: 1px solid ${colors.uiRed[400]};
  border-radius: 4px;
  padding: 12px;
  background-color: ${colors.uiRed[100]};
  margin-bottom: 17px;
`

interface ErrorProps {
  errorCode: string
}
export const ApiError = ({ errorCode }: ErrorProps) => {
  return !!errorCode ? (
    <ErrorMessageContainer>
      <div>
        <Icon />
      </div>
      <Typography.Text>{getErrorMessage(errorCode)}</Typography.Text>
    </ErrorMessageContainer>
  ) : null
}
