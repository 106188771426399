import { Button, LoadingSpinner, SpinnerSize, Typography } from '@paddle/ant-component-library'
import { Field, Formik } from 'formik'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { object, string } from 'yup'

import { routes } from '../../../constants/routes'
import {
  Container,
  ApiError,
  Form,
  FormRow,
  InputField,
  LoadingContainer,
  MainContent,
  Title,
  LoadingContentWrapper,
} from '../../shared/components'
import { RecoveryConstants } from './constants'
import { useOnSubmit } from './hooks'

interface LocationState {
  state: { email: string }
}

const StyledCallToAction = styled(Button)`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 40px;
  height: 40px;
`

const Link = styled.a`
  text-decoration: underline;
`

const initialValues = { recoveryCode: '' }

const validationSchema = object().shape({
  recoveryCode: string()
    .required(RecoveryConstants.RequiredError)
    .matches(/^[0-9a-f-]{36}$/, RecoveryConstants.RecoveryCodeLength),
})

export const RecoveryPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const email = (location as LocationState).state?.email

  const { errorCode, onSubmit, isPending, isSuccess } = useOnSubmit()

  const isProcessing = isPending || isSuccess

  useEffect(() => {
    if (!email) {
      return navigate(routes.login)
    }
  }, [email, navigate])

  return (
    <MainContent>
      <Container>
        <Title>{RecoveryConstants.Title}</Title>
        <Typography.Text>{RecoveryConstants.Subtitle}</Typography.Text>

        <LoadingContentWrapper isLoading={isProcessing}>
          {isProcessing ? (
            <LoadingContainer>
              <LoadingSpinner size={SpinnerSize.Medium} />
            </LoadingContainer>
          ) : null}
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <ApiError errorCode={errorCode || ''} />
                <FormRow>
                  <Field
                    name="recoveryCode"
                    id="recoveryCode-input"
                    disabled={isSubmitting}
                    required={true}
                    component={InputField}
                    placeholder={RecoveryConstants.Placeholder}
                  />
                </FormRow>
                <StyledCallToAction type="primary" htmlType="submit" disabled={isSubmitting}>
                  {RecoveryConstants.CTA}
                </StyledCallToAction>
              </Form>
            )}
          </Formik>
        </LoadingContentWrapper>

        <Typography.Text>{RecoveryConstants.CannotFindCode}</Typography.Text>
        <Link href={RecoveryConstants.MailTo}>{RecoveryConstants.ContactSupport}</Link>
        <Typography.Text>{RecoveryConstants.RequestReset}</Typography.Text>
      </Container>
    </MainContent>
  )
}
