export enum RecoveryConstants {
  Title = 'Account recovery',
  Subtitle = 'Can’t access your two-factor authentication device? You can still access your account by entering your recovery code.',
  CTA = 'Verify',
  Placeholder = 'Enter your 36-character recovery code including hyphens',
  RecoverAccount = 'Recover my account',
  RequiredError = 'Enter your recovery code',
  RecoveryCodeLength = 'Your recovery code must be exactly 36 characters, including hyphens',
  CannotFindCode = 'Can’t find your recovery code? ',
  ContactSupport = 'Contact support',
  RequestReset = ' to request a reset of your authentication settings.',
  MailTo = 'mailto:sellers@paddle.com?Subject=MFA%20help',
}
