export const SignUpLayoutConstant = {
  LogoAlt: 'Paddle',

  // switch to login
  LoginText: 'Already have an account?',
  LoginButtonLabel: 'Log in',
  LoginButtonTitle: 'Goto the login page',

  GoBackButtonLabel: 'Back',
  GoBackButtonLTitle: 'Return to the previous step',

  AsideMobileCollapsedIconAlt: 'View more',
  AsideMobileExpandedIconAlt: 'Close',

  DrawerId: 'sign-up-aside-drawer',
} as const

export const SignUpLayoutStyledConstant = {
  LayoutBreakpoint: '992px',
  MainWidth: 'min(480px, 100vw - 50px)', // use (2 * 24px) + 2px so it aligns with the logo
} as const
