import styled from 'styled-components'

export const LoadingContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-items: center;
`

export const LoadingContentWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};
`
