import * as Sentry from '@sentry/react'
import { environment } from 'environment'
import * as React from 'react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, Routes } from 'react-router-dom'

declare let SENTRY_PUBLIC_DSN: string | undefined
declare let GIT_COMMIT: string | undefined

export const sentryPublicDsn =
  typeof SENTRY_PUBLIC_DSN !== 'undefined' ? SENTRY_PUBLIC_DSN : import.meta.env.VITE_APP_SENTRY_PUBLIC_DSN
export const gitCommit = typeof GIT_COMMIT !== 'undefined' ? GIT_COMMIT : import.meta.env.VITE_APP_GIT_COMMIT

export const isSentryEnabled = () => {
  return !!sentryPublicDsn
}

export const SentryRoutesWrapper = Sentry.withSentryReactRouterV6Routing(Routes)

export const init = () => {
  Sentry.init({
    dsn: sentryPublicDsn,
    // Ensures we only run sentry on production (or whenever the dsn is set)
    enabled: isSentryEnabled(),
    release: gitCommit,
    environment: environment.ENV,

    // This enables included instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [
      // new ExtraErrorData({ depth: 10 }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 10,
    // To set up how many of all transactions we want to use for our performance analysis
    tracesSampleRate: 0.5,
  })
}
