import { ReactNode } from 'react'
import styled from 'styled-components'

import { SignUpLayoutStyledConstant } from 'components/pages/sign-up/sign-up-page-layout/constants'
import { SignUpAside } from 'components/pages/sign-up/sign-up-page-layout/sign-up-aside'
import { SignUpHeader } from 'components/pages/sign-up/sign-up-page-layout/sign-up-header'

const StyledDivContainer = styled.div`
  display: grid;
  height: 100dvh;

  // mobile default
  grid-template-columns: 1fr;
  grid-template-rows: 264px 1fr 65px;
  grid-template-areas:
    'header'
    'main'
    'aside';

  header {
    grid-area: header;
  }

  main {
    grid-area: main;
  }

  aside {
    grid-area: aside;
  }

  // desktop
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    grid-template-columns: 420px 1fr;
    grid-template-rows: 264px 1fr;
    grid-template-areas:
      'aside header'
      'aside main ';
  }
`

const StyledMain = styled.main`
  width: ${SignUpLayoutStyledConstant.MainWidth};
  margin-inline: auto;
`

interface Props {
  asideContent: ReactNode
  asideAriaLabel?: string
  children: ReactNode
  currentStep: number
  title: string
  subtitle?: string
  onGoBack: () => void
}

export const SignUpPageLayout = (props: Props) => {
  const { asideContent, asideAriaLabel, children, currentStep, title, subtitle, onGoBack } = props
  return (
    <StyledDivContainer>
      <SignUpHeader currentStep={currentStep} onGoBack={onGoBack} title={title} subtitle={subtitle} />

      <StyledMain>{children}</StyledMain>

      <SignUpAside aria-label={asideAriaLabel} currentStep={currentStep} mobileTitle={'What our customers say'}>
        {asideContent}
      </SignUpAside>
    </StyledDivContainer>
  )
}
