import { LoadingSpinner, SpinnerSize, Typography } from '@paddle/ant-component-library'
import { Formik, Field } from 'formik'
import { useLocation } from 'react-router-dom'
import { object, string } from 'yup'

import {
  ApiError,
  SignUp,
  Title,
  MainContent,
  Container,
  Form,
  FormRow,
  InputField,
  LoadingContentWrapper,
  LoadingContainer,
} from 'components/shared/components'
import { routes } from 'constants/routes'

import { TwoFAConstants } from './constants'
import { useOnSubmit } from './hooks'
import { StyledCallToAction, StyledRouterLink } from './two-factor-authentication.styled'
import { LocationState } from './types'

const initialValues = { code: '' }

const validationSchema = object().shape({
  code: string()
    .required(TwoFAConstants.RequiredError)
    .matches(/^[0-9]{6}$/, TwoFAConstants.RecoveryCodeLength),
})

export const TwoFAPage = () => {
  const location = useLocation()
  const { email, password } = (location as LocationState)?.state || {}

  const { errorCode, onSubmit, isPending, isSuccess, needMoreAction } = useOnSubmit()

  const isProcessing = isPending || isSuccess || needMoreAction

  return (
    <MainContent data-testid="twofa-page">
      <Container>
        <Title>{TwoFAConstants.Title}</Title>
        <Typography.Text>{TwoFAConstants.Subtitle}</Typography.Text>

        <LoadingContentWrapper isLoading={isProcessing}>
          {isProcessing ? (
            <LoadingContainer>
              <LoadingSpinner size={SpinnerSize.Medium} />
            </LoadingContainer>
          ) : null}
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
          >
            <Form>
              <ApiError errorCode={errorCode || ''} />
              <FormRow>
                <Field
                  name="code"
                  id="code-input"
                  disabled={isProcessing}
                  required={true}
                  component={(props: any) => <InputField {...props} autoComplete={false} />}
                  placeholder={TwoFAConstants.EnterCode}
                  autoFocus={true}
                />
              </FormRow>
              <StyledCallToAction type="primary" htmlType="submit" disabled={isProcessing}>
                {TwoFAConstants.CTA}
              </StyledCallToAction>
            </Form>
          </Formik>
        </LoadingContentWrapper>

        <Typography.Text>{TwoFAConstants.CannotAccess}</Typography.Text>
        <StyledRouterLink to={routes.recovery} state={{ email, password }}>
          {TwoFAConstants.RecoverAccount}
        </StyledRouterLink>
      </Container>

      <SignUp />
    </MainContent>
  )
}
