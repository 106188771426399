import { DefaultFeatureFlags, FeatureFlags } from 'feature-flags'
import { LaunchDarkly } from 'libraries/launchdarkly/launchdarkly'
import { useEffect, useState } from 'react'

interface useFeatureFlagResult<TFeatureFlag extends keyof FeatureFlags> {
  isLoading: boolean
  featureFlag: FeatureFlags[TFeatureFlag]
}

export function useFeatureFlag<TFeatureFlag extends keyof FeatureFlags>(
  flag: TFeatureFlag,
): useFeatureFlagResult<TFeatureFlag> {
  const [isLoading, setIsLoading] = useState(import.meta.env.MODE !== 'test')

  useEffect(() => {
    if (!LaunchDarkly.isInitialized()) {
      LaunchDarkly.init()
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [flag])

  const defaultValue = DefaultFeatureFlags[flag]
  const featureFlag = LaunchDarkly.getFlag(flag, defaultValue) ?? defaultValue

  return { isLoading, featureFlag }
}
