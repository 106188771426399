import { SentryRoutesWrapper } from 'libraries/sentry'
import { Route } from 'react-router-dom'

import { SignUp } from 'components/pages/sign-up/sign-up'
import { routes } from 'constants/routes'

export function PaddleBillingRoutes() {
  return (
    <SentryRoutesWrapper>
      <Route path={routes.signup} element={<SignUp />} />
    </SentryRoutesWrapper>
  )
}
