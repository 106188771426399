import { Checkbox, CheckboxChangeEvent, colors, InfoCircleOutlined, Tooltip } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { SignupFieldNames, UnsupportedAndRestrictedProductsList } from 'components/pages/sign-up/constants'
import { SignUpLayoutStyledConstant } from 'components/pages/sign-up/sign-up-page-layout/constants'

interface StyledProductCardProps {
  selected: boolean
  supported: boolean
}

const StyledProductCard = styled.div<StyledProductCardProps>`
  && {
    height: 86px;
    border: 1px solid ${colors.padBlue[200]};
    border-radius: 4px;
    ${({ selected }) => (selected ? `border: 1px solid ${colors.elecBlue[500]};` : '')};

    .ant-checkbox-wrapper {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      span {
        display: flex;
        align-items: center;
        gap: 0px 10px;
      }
    }
    @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
      height: 50px;

      .ant-checkbox-wrapper {
        align-items: center;
        padding: 0px 16px;
        width: 100%;
        height: 100%;
        .ant-checkbox {
          top: 0px;
        }
      }
    }
  }
`

const StyledH4 = styled.h4`
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
`

interface Props {
  value: string[]
  onChange: (fieldName: string, value: string[]) => void
  id: string
  tooltip: string
  title: string
}
export function SignUpProductCard({ value, onChange, id, title, tooltip }: Props) {
  const handleProductSelection = (id: string) => (e: CheckboxChangeEvent) => {
    let updatedValues = []
    if (e.target.checked) {
      updatedValues = [...value, id]
    } else {
      updatedValues = value.filter((product) => {
        return product !== id
      })
    }
    onChange(SignupFieldNames.Products, updatedValues)
  }

  return (
    <StyledProductCard selected={value.includes(id)} supported={!UnsupportedAndRestrictedProductsList.includes(id)}>
      <Checkbox checked={value.includes(id)} onChange={handleProductSelection(id)}>
        <StyledH4>{title}</StyledH4>
        <Tooltip title={tooltip}>
          <InfoCircleOutlined style={{ color: colors.padBlue[400] }} />
        </Tooltip>
      </Checkbox>
    </StyledProductCard>
  )
}
