import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { getUserInfo } from 'components/pages/sign-up/api/user-info'
import { Userinfo } from 'components/pages/sign-up/types'
import { USE_QUERY_KEYS } from 'constants/query-keys'

export const useUserInfo = (options?: UseQueryOptions<Userinfo>) => {
  return useQuery<Userinfo>({
    queryKey: [USE_QUERY_KEYS.Userinfo],
    queryFn: () => getUserInfo(),
    ...options,
  })
}
