import { colors, FormItem, Input, InputNumber } from '@paddle/ant-component-library'
import { FieldProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { InputFieldProps, InputType, LayoutType } from '../form'

const StyledInput = styled(Input)`
  height: 40px;
  &::placeholder {
    color: ${colors.padBlue[400]};
  }
`

const verticalStyle = { flexDirection: 'column' }

export const InputField: React.FunctionComponent<FieldProps<any> & InputFieldProps> = ({
  field, // name, value, onBlur etc
  form: { getFieldMeta, setFieldValue },
  ...props
}) => {
  const {
    'aria-label': ariaLabel,
    layout,
    disabled,
    id,
    label,
    required,
    colon,
    type = InputType.String,
    layoutType = LayoutType.Horizontal,
    min,
    max,
    step,
    formatter,
    parser,
    precision,
    placeholder,
    maxLength,
    isPassword,
    inputStyle = {},
    hideErrorMessage = false,
    autoFocus = false,
    autoComplete,
    extra,
    addonBefore,
  } = props

  const meta = getFieldMeta(field.name)
  const errorMessage = meta.touched && meta.error

  const emptyValue = type === InputType.String ? '' : 0
  const onChange = (value: number | string | null) =>
    !!value ? setFieldValue(field.name, value) : setFieldValue(field.name, emptyValue)

  const passwordOptions = isPassword ? { type: 'password', autoComplete: 'off' } : {}

  return (
    <FormItem
      label={label}
      htmlFor={id}
      {...layout}
      style={layoutType === LayoutType.Vertical ? verticalStyle : {}}
      required={required}
      help={!hideErrorMessage && errorMessage ? errorMessage : undefined}
      colon={colon}
      type={type === InputType.Numeric ? 'number' : 'text'}
      validateStatus={errorMessage ? 'error' : undefined}
      extra={errorMessage ? '' : extra}
    >
      {type === InputType.Numeric ? (
        <InputNumber
          id={id}
          aria-label={ariaLabel}
          data-testid={id}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          {...field}
          value={field.value}
          formatter={formatter}
          parser={parser}
          onChange={onChange}
          style={inputStyle}
          precision={precision}
          autoFocus={autoFocus}
          autoComplete={autoComplete === false ? 'off' : undefined}
        />
      ) : (
        <StyledInput
          id={id}
          aria-label={ariaLabel}
          disabled={disabled}
          {...field}
          placeholder={placeholder}
          maxLength={maxLength}
          {...passwordOptions}
          style={inputStyle}
          autoFocus={autoFocus}
          autoComplete={autoComplete === false ? 'off' : undefined}
          addonBefore={addonBefore}
        />
      )}
    </FormItem>
  )
}
