import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { redirect } from 'libraries/redirect'
import { useCallback, useContext, useEffect } from 'react'

import { AppContext } from 'components/layout/Layout'
import { consentInfo, submitConsent } from 'modules/api/consent'
import { ApiErrorResponse, useGetFirstApiError } from 'modules/api/hooks'

export const useSubmitConsent = (scopes: string[]) => {
  const { consentChallenge } = useContext(AppContext)
  const { error, onErrorCallback } = useGetFirstApiError()
  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationFn: (challenge: string) => submitConsent(scopes, challenge),
    onSuccess: (response) => {
      // Redirect on success
      const redirectLocation = response?.data?.data?.redirect_to
      if (redirectLocation) {
        redirect(redirectLocation)
      }
    },
    onError: onErrorCallback,
  })

  const onClick = useCallback(async () => {
    mutate(consentChallenge)
  }, [mutate, consentChallenge])

  return {
    errorCode: error?.code,
    isError,
    isPending,
    isSuccess,
    onClick,
  }
}

export const useConsentInfo = () => {
  const { consentChallenge } = useContext(AppContext)
  const { error, onErrorCallback } = useGetFirstApiError()
  const {
    data,
    error: useQueryError,
    isPending,
    isFetching,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['consent-info', consentChallenge],
    queryFn: () => consentInfo(consentChallenge),
    enabled: !!consentChallenge,
    select: (response) => response.data.data,
  })

  // This probably isn't the best way to handle this
  // see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
  // but I'm not sure how useGetFirstApiError fits into the rest of the app
  // This should be fine though as this hook is only used by the consent page
  useEffect(() => {
    if (useQueryError) {
      onErrorCallback(useQueryError as AxiosError<ApiErrorResponse>)
    }
  }, [onErrorCallback, useQueryError])

  return {
    errorCode: error?.code,
    isLoading: isPending || isFetching,
    isError,
    isSuccess,
    scopes: data?.scopes,
  }
}

export const useCancelConsent = () => {
  const onCancel = useCallback(() => {
    // @todo not-implemented-yet

    window.history.back()
  }, [])

  return onCancel
}
