import { Col, Row } from '@paddle/ant-component-library'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  SignupFieldHelpText,
  SignupFieldLabels,
  SignupFieldNames,
  SignupFormAverageRevenueOptions,
  SignupFormBusinessTypeOptions,
  SignupFormLayout,
  SignupWebsitePrefix,
} from 'components/pages/sign-up/constants'
import { SignupFormValues } from 'components/pages/sign-up/types'
import { FormSelect, InputField, LayoutType } from 'components/shared/components'

const StyledDiv = styled.div`
  margin-top: 40px;
`

const StyledRow = styled(Row)`
  .ant-form-item-extra {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`

export function SignupBasicBusinessDetails() {
  const { isSubmitting } = useFormikContext<SignupFormValues>()

  return (
    <StyledDiv>
      <StyledRow gutter={[20, 40]}>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.BusinessName}
            id={SignupFieldNames.BusinessName}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.BusinessName}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.BusinessType}
            id={SignupFieldNames.BusinessType}
            disabled={isSubmitting}
            required={true}
            showSearch={false}
            component={FormSelect}
            options={SignupFormBusinessTypeOptions}
            label={SignupFieldLabels.BusinessType}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.AnnualRevenue}
            id={SignupFieldNames.AnnualRevenue}
            disabled={isSubmitting}
            required={true}
            options={SignupFormAverageRevenueOptions}
            component={FormSelect}
            label={SignupFieldLabels.AnnualRevenueCopy2}
            showSearch={false}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Website}
            id={SignupFieldNames.Website}
            disabled={isSubmitting}
            component={InputField}
            label={SignupFieldLabels.Website}
            placeholder={SignupFieldHelpText.WebsitePlaceholder}
            addonBefore={SignupWebsitePrefix}
            extra={SignupFieldHelpText.OptionalFieldText}
          />
        </Col>
      </StyledRow>
    </StyledDiv>
  )
}
