import { Col, InfoCircleOutlined, Row, Space, Tooltip } from '@paddle/ant-component-library'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  SignupFieldHelpText,
  SignupFieldLabels,
  SignupFieldNames,
  SignupFormLayout,
} from 'components/pages/sign-up/constants'
import { CountriesOptions } from 'components/pages/sign-up/countries'
import { SignupFormValues } from 'components/pages/sign-up/types'
import { FormSelect, InputField, LayoutType } from 'components/shared/components'

const StyledDiv = styled.div`
  margin-top: 40px;
`

export function SignupPublicBusinessDetails() {
  const { isSubmitting } = useFormikContext<SignupFormValues>()

  return (
    <StyledDiv>
      <Row gutter={[20, 40]}>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Country}
            id={SignupFieldNames.Country}
            disabled={isSubmitting}
            required={true}
            component={FormSelect}
            label={SignupFieldLabels.CountryCopy2}
            options={CountriesOptions}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Stock}
            id={SignupFieldNames.Stock}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            placeholder={SignupFieldHelpText.StockPlaceholder}
            label={
              <Space align={'center'}>
                {SignupFieldLabels.Stock}
                <Tooltip title={SignupFieldHelpText.StockTicker}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            }
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.RegistrationNumber}
            id={SignupFieldNames.RegistrationNumber}
            disabled={isSubmitting}
            colon={false}
            component={InputField}
            label={SignupFieldLabels.RegistrationNumber}
          />
        </Col>
      </Row>
    </StyledDiv>
  )
}
