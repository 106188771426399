import { AxiosError } from 'axios'
import { Formik, FormikHelpers } from 'formik'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useLoginMutation } from 'components/pages/login/hooks'
import { SignupErrorResponse, getSignupErrorMessage } from 'components/pages/sign-up/api/post-seller'
import { useSellerMutation } from 'components/pages/sign-up/hooks/useSellerMutation'
import { useUserInfo } from 'components/pages/sign-up/hooks/useUserInfo'
import { SignupForm } from 'components/pages/sign-up/sign-up-form/sign-up-form'
import { SignUpValidationSchema } from 'components/pages/sign-up/sign-up-form/sign-up-form-validation-schema'
import { SignupFormValues, SignUpVersionType } from 'components/pages/sign-up/types'
import { EVENTS } from 'modules/analytics/constants'
import { trackEvents } from 'modules/analytics/eventTracking'

interface Props {
  currentStep: number
  onPageChange: (pageNumber: number) => void
  signUpVersion?: SignUpVersionType
}

export function SignupFormWrapper({ currentStep, onPageChange, signUpVersion = 'extended' }: Props) {
  const [params] = useSearchParams()
  const { isSuccess: isUserAuthenticationCalloutShown } = useUserInfo()

  const initialValues: Partial<SignupFormValues> = {
    signUpVersion,
    products: [],
    acceptedTermsOfService: false,
    marketingConsent: false,
    hasPaddleAccount: false,
    utm: {
      campaign: params.get('utm_campaign'),
      content: params.get('utm_content'),
      medium: params.get('utm_medium'),
      ref: params.get('ref'),
      source: params.get('utm_source'),
      term: params.get('utm_term'),
    },
  }

  const [errorMessage, setErrorMessage] = useState('')
  const {
    mutateAsync,
    isPending: signupLoading,
    isSuccess: signupSuccess,
  } = useSellerMutation(isUserAuthenticationCalloutShown)
  const { onSubmit, isPending: loginLoading, isSuccess: loginSuccess, needMoreAction } = useLoginMutation()

  const isLoading = signupLoading || signupSuccess || loginLoading || loginSuccess || needMoreAction

  const handleErrorMessage = (errorMessage: string) => {
    setErrorMessage(errorMessage)
  }

  const handleSubmit = async (values: SignupFormValues, { setSubmitting }: FormikHelpers<SignupFormValues>) => {
    try {
      await mutateAsync(values, {
        onSuccess: () => {
          trackEvents(EVENTS.SIGNUP_FORM_SUBMISSION)
          setErrorMessage('')
          onSubmit({ email: (values.email ?? '').trim(), password: values.password, remember: false })
        },
        onError: (error) => {
          setErrorMessage(getSignupErrorMessage(error as AxiosError<SignupErrorResponse>))
          setSubmitting(false)
        },
      })
    } catch {
      // this is handled by react-query
    }
  }

  return (
    <Formik
      initialValues={initialValues as SignupFormValues}
      onSubmit={handleSubmit}
      validationSchema={SignUpValidationSchema}
      validateOnChange={true}
    >
      {() => (
        <SignupForm
          currentStep={currentStep}
          onPageChange={onPageChange}
          onValidate={handleErrorMessage}
          errorMessage={errorMessage}
          disableSubmit={isLoading}
          isUserAuthenticationCalloutShown={isUserAuthenticationCalloutShown}
          signUpVersion={signUpVersion}
        />
      )}
    </Formik>
  )
}
