export const routes = {
  root: '/',
  login: '/login',
  signup: '/signup',
  signupPhase2: '/signup-phase-2',
  passwordReset: '/password/reset',
  twoFA: '/2fa',
  passwordResetRequired: '/password/reset-required',
  recovery: '/2fa-recovery-code',
  consent: '/oauth2/consent',
  permissionError: '/permission-error',
  oauthError: '/oauth-error',
  redirect: '/redirect',
}
