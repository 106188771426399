import { Checkbox, colors, FormItem } from '@paddle/ant-component-library'
import { FieldProps } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { InputFieldProps } from '../form'

const StyledCheckboxField = styled(FormItem)`
  label {
    font-weight: normal;
    font-size: 13px;
    color: ${colors.padBlue[500]};

    &::after {
      margin: 0 4px 0 0;
    }
  }
`

export const CheckboxField: React.FunctionComponent<FieldProps<any> & InputFieldProps> = ({
  field, // name, value, onBlur etc
  form: { setFieldValue },
  ...props
}) => {
  const { 'aria-label': ariaLabel, layout, disabled, id, label, required, colon, autoFocus = false } = props

  const [checked, setChecked] = React.useState(false)

  const handleChange = () => {
    setChecked(!checked)
    setFieldValue(field.name, !checked)
  }

  return (
    <StyledCheckboxField label={label} htmlFor={id} {...layout} required={required} colon={colon}>
      <Checkbox
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        {...field}
        autoFocus={autoFocus}
        checked={checked}
        onChange={handleChange}
      />
    </StyledCheckboxField>
  )
}
