import { array as YupArray, boolean as YupBoolean, object as YupObject, string as YupString } from 'yup'

import {
  SignupFieldHelpText,
  SignupFieldLabels,
  SignupFieldNames,
  SignupFormBusinessTypeValues,
} from 'components/pages/sign-up/constants'
import { fieldIncorrectFormatMessage, fieldRequiredMessage } from 'utils/validation-helpers'

const SignupCustomFieldNames = {
  Terms: 'Terms and conditions',
  AnnualRevenue: 'Average monthly revenue',
  ExistingVendorId: 'Vendor ID',
} as const

// Copied as-is from Yup validator.
// We are using a custom regex to skip `https?://` prefix as the default regex has it, however we don't want it.
const CustomURLRegex =
  /^((https?|ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i // eslint-disable-line

export const SignUpValidationSchema = YupObject().shape({
  [SignupFieldNames.Products]: YupArray().required(fieldRequiredMessage(SignupFieldLabels.Products)).min(1),
  [SignupFieldNames.TermsAndConditions]: YupBoolean().oneOf([true], fieldRequiredMessage(SignupCustomFieldNames.Terms)),
  [SignupFieldNames.FirstName]: YupString().trim().required(fieldRequiredMessage(SignupFieldLabels.FirstName)),
  [SignupFieldNames.LastName]: YupString().trim().required(fieldRequiredMessage(SignupFieldLabels.LastName)),
  [SignupFieldNames.Email]: YupString()
    .trim()
    .required(fieldRequiredMessage(SignupFieldLabels.PrimaryEmailAddress))
    .email(fieldIncorrectFormatMessage(SignupFieldLabels.PrimaryEmailAddress)),
  [SignupFieldNames.Password]: YupString()
    .required(fieldRequiredMessage(SignupFieldHelpText.PasswordHelpText))
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, SignupFieldHelpText.PasswordHelpText),
  [SignupFieldNames.BusinessName]: YupString()
    .trim()
    .when(SignupFieldNames.SignUpVersion, {
      is: (signUpVersion: string) => signUpVersion === 'extended',
      then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.BusinessName)),
      otherwise: (schema) => schema.notRequired(),
    })
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d).+|(?=.*[A-Za-z].*[A-Za-z]).+/g,
      fieldIncorrectFormatMessage(SignupFieldLabels.BusinessName),
    ),
  [SignupFieldNames.BusinessType]: YupString().when(SignupFieldNames.SignUpVersion, {
    is: (signUpVersion: string) => signUpVersion === 'extended',
    then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.BusinessType)),
    otherwise: (schema) => schema.notRequired(),
  }),
  [SignupFieldNames.AnnualRevenue]: YupString().when(SignupFieldNames.SignUpVersion, {
    is: (signUpVersion: string) => signUpVersion === 'extended',
    then: (schema) => schema.required(fieldRequiredMessage(SignupCustomFieldNames.AnnualRevenue)),
    otherwise: (schema) => schema.notRequired(),
  }),
  [SignupFieldNames.Website]: YupString()
    .trim()
    .matches(CustomURLRegex, {
      name: 'url',
      message: fieldIncorrectFormatMessage(SignupFieldLabels.Website),
      excludeEmptyString: false,
    }),
  [SignupFieldNames.Country]: YupString().when(SignupFieldNames.SignUpVersion, {
    is: (signUpVersion: string) => signUpVersion === 'extended',
    then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.Country)),
    otherwise: (schema) => schema.notRequired(),
  }),
  [SignupFieldNames.StreetAddress]: YupString()
    .trim()
    .when([SignupFieldNames.BusinessType, SignupFieldNames.SignUpVersion], {
      is: (businessType: string, signUpVersion: string) =>
        businessType !== SignupFormBusinessTypeValues.Public && signUpVersion === 'extended',
      then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.StreetAddress)),
      otherwise: (schema) => schema.notRequired(),
    }),
  [SignupFieldNames.City]: YupString()
    .trim()
    .when([SignupFieldNames.BusinessType, SignupFieldNames.SignUpVersion], {
      is: (businessType: string, signUpVersion: string) =>
        businessType !== SignupFormBusinessTypeValues.Public && signUpVersion === 'extended',
      then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.City)),
      otherwise: (schema) => schema.notRequired(),
    }),
  [SignupFieldNames.Postcode]: YupString()
    .trim()
    .when([SignupFieldNames.BusinessType, SignupFieldNames.SignUpVersion], {
      is: (businessType: string, signUpVersion: string) =>
        businessType !== SignupFormBusinessTypeValues.Public && signUpVersion === 'extended',
      then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.Postcode)),
      otherwise: (schema) => schema.notRequired(),
    }),
  [SignupFieldNames.Stock]: YupString().when([SignupFieldNames.BusinessType, SignupFieldNames.SignUpVersion], {
    is: (businessType: string, signUpVersion: string) =>
      businessType === SignupFormBusinessTypeValues.Public && signUpVersion === 'extended',
    then: (schema) => schema.required(fieldRequiredMessage(SignupFieldLabels.Stock)),
    otherwise: (schema) => schema.notRequired(),
  }),
})
