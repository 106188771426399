export function GamblingProductsIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="6.5" width="25" height="37" rx="1.1" fill="#D2D4DE" stroke="#73809C" />
      <path
        d="M2.77661 13.0342L4.16636 8.87656H5.17814L6.55044 13.0342H5.74799L5.41655 12.0049H3.8291L3.50347 13.0342H2.77661ZM4.62573 9.51037L4.00936 11.4409H5.23629L4.62573 9.51037Z"
        fill="#73809C"
      />
      <rect
        x="17.0605"
        y="2.61244"
        width="25"
        height="37"
        rx="1.1"
        transform="rotate(15.0104 17.0605 2.61244)"
        fill="white"
        stroke="#73809C"
      />
      <path
        d="M17.5424 9.60831L19.9765 5.89665L20.9537 6.1587L21.1874 10.5857L20.4124 10.3779L20.3625 9.28411L18.8292 8.87297L18.2444 9.79656L17.5424 9.60831ZM20.2537 6.63633L19.1514 8.36728L20.3365 8.68505L20.2537 6.63633Z"
        fill="#73809C"
      />
      <path
        d="M26.1428 40.4855L28.5566 36.8047L29.5258 37.0646L29.7575 41.4548L28.9889 41.2487L28.9394 40.164L27.4189 39.7563L26.839 40.6722L26.1428 40.4855ZM28.8316 37.5382L27.7385 39.2548L28.9137 39.5699L28.8316 37.5382Z"
        fill="#73809C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4093 19.7566L24.9112 18.8454C24.5025 18.0979 23.8058 17.5501 22.9829 17.3295L22.8623 17.2972C21.7876 17.009 20.6426 17.3572 19.9103 18.1948C19.1503 19.064 18.9745 20.2997 19.4618 21.3464L22.9651 28.8717L29.7635 24.1088C30.7091 23.4462 31.175 22.2884 30.9519 21.1556C30.7369 20.0639 29.9197 19.1896 28.845 18.9014L28.7244 18.8691C27.9015 18.6484 27.0242 18.7741 26.2964 19.2169L25.4093 19.7566Z"
        fill="#D2D4DE"
      />
      <path
        d="M24.9112 18.8454L24.4725 19.0853L24.4725 19.0853L24.9112 18.8454ZM25.4093 19.7566L24.9705 19.9964L25.2217 20.4559L25.6691 20.1837L25.4093 19.7566ZM19.9103 18.1948L20.2867 18.5239L20.2867 18.5239L19.9103 18.1948ZM19.4618 21.3464L19.915 21.1354L19.4618 21.3464ZM22.9651 28.8717L22.5118 29.0828L22.7635 29.6235L23.252 29.2812L22.9651 28.8717ZM29.7635 24.1088L30.0504 24.5183L29.7635 24.1088ZM30.9519 21.1556L31.4425 21.059L31.4425 21.059L30.9519 21.1556ZM28.845 18.9014L28.7155 19.3843L28.845 18.9014ZM28.7244 18.8691L28.8539 18.3861L28.7244 18.8691ZM26.2964 19.2169L26.5563 19.644L26.5563 19.644L26.2964 19.2169ZM24.4725 19.0853L24.9705 19.9964L25.848 19.5167L25.3499 18.6056L24.4725 19.0853ZM22.8534 17.8124C23.5443 17.9977 24.1293 18.4576 24.4725 19.0853L25.3499 18.6056C24.8757 17.7382 24.0672 17.1026 23.1124 16.8466L22.8534 17.8124ZM22.7328 17.7801L22.8534 17.8124L23.1124 16.8466L22.9918 16.8142L22.7328 17.7801ZM20.2867 18.5239C20.8935 17.8298 21.8423 17.5413 22.7328 17.7801L22.9918 16.8142C21.7329 16.4767 20.3917 16.8845 19.5338 17.8657L20.2867 18.5239ZM19.915 21.1354C19.5113 20.268 19.657 19.2442 20.2867 18.5239L19.5338 17.8657C18.6436 18.8839 18.4377 20.3313 19.0085 21.5574L19.915 21.1354ZM23.4184 28.6607L19.915 21.1354L19.0085 21.5574L22.5118 29.0828L23.4184 28.6607ZM23.252 29.2812L30.0504 24.5183L29.4766 23.6993L22.6782 28.4622L23.252 29.2812ZM30.0504 24.5183C31.158 23.7422 31.7038 22.3859 31.4425 21.059L30.4613 21.2522C30.6462 22.1909 30.2601 23.1503 29.4766 23.6993L30.0504 24.5183ZM31.4425 21.059C31.1906 19.7802 30.2334 18.756 28.9745 18.4185L28.7155 19.3843C29.606 19.6231 30.2832 20.3476 30.4613 21.2522L31.4425 21.059ZM28.9745 18.4185L28.8539 18.3861L28.5949 19.352L28.7155 19.3843L28.9745 18.4185ZM28.8539 18.3861C27.8991 18.1301 26.8811 18.2759 26.0365 18.7897L26.5563 19.644C27.1674 19.2723 27.904 19.1667 28.5949 19.352L28.8539 18.3861ZM26.0365 18.7897L25.1494 19.3294L25.6691 20.1837L26.5563 19.644L26.0365 18.7897Z"
        fill="#73809C"
      />
      <circle cx="36.5" cy="35.5" r="11.5" fill="white" />
      <path d="M36.5006 35.5L31.0006 26L33.0006 25L36.5006 24.5V35.5Z" fill="#D2D4DE" />
      <path d="M37.2739 35.9665L41.635 25.8927L43.6289 26.9048L46.1137 29.4199L37.2739 35.9665Z" fill="#D2D4DE" />
      <path d="M37.0007 34.5311L47.9446 35.386L47.6346 37.6004L46.0623 40.7671L37.0007 34.5311Z" fill="#D2D4DE" />
      <path d="M36.0016 36.1585L41.772 45.4967L39.8015 46.5537L36.3173 47.154L36.0016 36.1585Z" fill="#D2D4DE" />
      <path d="M37.1455 36.0005L30.5091 44.7445L28.8139 43.2864L27.0001 40.2515L37.1455 36.0005Z" fill="#D2D4DE" />
      <path d="M36.2307 35.9235L25.264 35.4425L25.4983 33.2187L26.9616 30.0003L36.2307 35.9235Z" fill="#D2D4DE" />
      <path d="M36.5016 24.5001L36.5016 46.5001" stroke="#73809C" />
      <path d="M47.4989 35.4991L25.4989 35.4991" stroke="#73809C" />
      <path d="M46.0265 29.9996L26.9739 40.9996" stroke="#73809C" />
      <path d="M42.0011 25.9742L31.0011 45.0268" stroke="#73809C" />
      <path d="M42.0011 45.0258L31.0011 25.9733" stroke="#73809C" />
      <path d="M46.0265 41.0004L26.9739 30.0004" stroke="#73809C" />
      <circle cx="36.4996" cy="35.4989" r="7.99999" fill="white" stroke="#73809C" />
      <circle cx="36.4986" cy="35.498" r="7.99999" fill="white" stroke="#73809C" />
      <path
        d="M32.0005 35.0559L32.0005 35.9344C32.5381 35.9346 33.0705 36.0398 33.5671 36.2441C34.0637 36.4485 34.5149 36.7479 34.8948 37.1253C35.2747 37.5027 35.5759 37.9507 35.7812 38.4436C35.9864 38.9365 36.0918 39.4648 36.0911 39.9981L36.9098 39.9981C36.9101 38.922 37.3412 37.89 38.1083 37.1291C38.8753 36.3682 39.9157 35.9406 41.0005 35.9403L41.0005 35.0618C40.4628 35.0616 39.9305 34.9564 39.4339 34.7521C38.9372 34.5477 38.4861 34.2483 38.1062 33.8709C37.7263 33.4935 37.4251 33.0455 37.2198 32.5526C37.0145 32.0597 36.9092 31.5314 36.9098 30.9981L36.0911 30.9981C36.0909 32.0742 35.6598 33.1062 34.8927 33.8671C34.1256 34.6281 33.0853 35.0557 32.0005 35.0559Z"
        fill="#73809C"
      />
      <mask id="path-24-inside-1_343_20263" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5006 47C42.8519 47 48.0006 41.8513 48.0006 35.5C48.0006 29.1487 42.8519 24 36.5006 24C30.1493 24 25.0006 29.1487 25.0006 35.5C25.0006 41.8513 30.1493 47 36.5006 47ZM36.5006 46C42.2996 46 47.0006 41.299 47.0006 35.5C47.0006 29.701 42.2996 25 36.5006 25C30.7016 25 26.0006 29.701 26.0006 35.5C26.0006 41.299 30.7016 46 36.5006 46Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5006 47C42.8519 47 48.0006 41.8513 48.0006 35.5C48.0006 29.1487 42.8519 24 36.5006 24C30.1493 24 25.0006 29.1487 25.0006 35.5C25.0006 41.8513 30.1493 47 36.5006 47ZM36.5006 46C42.2996 46 47.0006 41.299 47.0006 35.5C47.0006 29.701 42.2996 25 36.5006 25C30.7016 25 26.0006 29.701 26.0006 35.5C26.0006 41.299 30.7016 46 36.5006 46Z"
        fill="white"
      />
      <path
        d="M48.0006 35.5L49.0006 35.5L48.0006 35.5ZM36.5006 24L36.5006 25L36.5006 24ZM25.0006 35.5L24.0006 35.5L25.0006 35.5ZM47.0006 35.5L46.0006 35.5L47.0006 35.5ZM36.5006 46L36.5006 47L36.5006 46ZM36.5006 25L36.5006 24L36.5006 25ZM26.0006 35.5L27.0006 35.5L26.0006 35.5ZM47.0006 35.5C47.0006 41.299 42.2996 46 36.5006 46L36.5006 48C43.4041 48 49.0006 42.4036 49.0006 35.5L47.0006 35.5ZM36.5006 25C42.2996 25 47.0006 29.701 47.0006 35.5L49.0006 35.5C49.0006 28.5964 43.4042 23 36.5006 23L36.5006 25ZM26.0006 35.5C26.0006 29.701 30.7016 25 36.5006 25L36.5006 23C29.5971 23 24.0006 28.5964 24.0006 35.5L26.0006 35.5ZM36.5006 46C30.7016 46 26.0006 41.299 26.0006 35.5L24.0006 35.5C24.0006 42.4035 29.597 48 36.5006 48L36.5006 46ZM46.0006 35.5C46.0006 40.7467 41.7473 45 36.5006 45L36.5006 47C42.8519 47 48.0006 41.8513 48.0006 35.5L46.0006 35.5ZM36.5006 26C41.7473 26 46.0006 30.2533 46.0006 35.5L48.0006 35.5C48.0006 29.1487 42.8519 24 36.5006 24L36.5006 26ZM27.0006 35.5C27.0006 30.2533 31.2539 26 36.5006 26L36.5006 24C30.1493 24 25.0006 29.1487 25.0006 35.5L27.0006 35.5ZM36.5006 45C31.2539 45 27.0006 40.7467 27.0006 35.5L25.0006 35.5C25.0006 41.8513 30.1493 47 36.5006 47L36.5006 45Z"
        fill="#73809C"
        mask="url(#path-24-inside-1_343_20263)"
      />
    </svg>
  )
}
