import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { environment } from 'environment'
import { redirect, safeRedirect } from 'libraries/redirect'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'

import { routes } from 'constants/routes'
import { COOKIE_REDIRECT_LOCATION_KEY } from 'constants/shared'
import { identityServicePaths } from 'modules/api/constants'
import { ApiErrorResponse, useGetFirstApiError } from 'modules/api/hooks'
import { doLogin, LoginValues } from 'modules/api/login'

import { LoginErrorConstant } from '../../../constants/api-errors'
import { LocationState } from './types'

export const useOnSubmit = () => {
  const [needMoreAction, setNeedMoreAction] = useState(false)
  const { error, onErrorCallback, removeErrorCallback } = useGetFirstApiError()
  const navigate = useNavigate()
  const location = useLocation()
  const [cookies, , deleteCookie] = useCookies([COOKIE_REDIRECT_LOCATION_KEY])
  const { email, password, remember } = (location as LocationState)?.state || {}
  const { mutate, isError, isSuccess, isPending } = useMutation({
    mutationFn: (values: Pick<LoginValues, 'code'>) => {
      removeErrorCallback()
      return doLogin({ email, password, code: values.code, remember })
    },

    onError: (error: AxiosError<ApiErrorResponse>, variables) => {
      const code = error?.response?.data?.error?.code

      if (!code) {
        onErrorCallback(error)
        return
      }

      if (code === LoginErrorConstant.employeeOktaRequired) {
        setNeedMoreAction(true)
        let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY] ?? environment.FALLBACK_REDIRECT_URL

        deleteCookie(COOKIE_REDIRECT_LOCATION_KEY)

        let employeeOktaUrl = new URL(environment.BASE_URL)
        employeeOktaUrl.pathname = identityServicePaths.employeeLoginPath
        employeeOktaUrl.searchParams.set('r', redirectLocation)
        employeeOktaUrl.searchParams.set('rem', String(remember))
        redirect(employeeOktaUrl.toString())
        return
      }

      onErrorCallback(error)
    },

    onSuccess: () => {
      let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY]
      deleteCookie(COOKIE_REDIRECT_LOCATION_KEY)
      safeRedirect(redirectLocation, navigate)
    },
  })

  useEffect(() => {
    if (!email || !password) {
      return navigate(routes.login)
    }
  }, [email, password, navigate])

  return {
    onSubmit: mutate,
    isError,
    isSuccess,
    isPending,
    needMoreAction,
    errorCode: error?.code,
  }
}
