declare let APP_ENV: string | undefined
declare let SIGNUP_ENDPOINT: string | undefined
declare let RESET_ENDPOINT: string | undefined
declare let LOGOUT_ENDPOINT: string | undefined
declare let HOTJAR_SITE_ID: number | undefined
declare let HUBSPOT_TRACKING_ID: number | undefined
declare let HOCKEYSTACK_TRACKING_ID: string | undefined
declare let FALLBACK_REDIRECT_URL: string | undefined
declare let ALLOWED_OAUTH_REDIRECTS: string
declare let API_ENDPOINT: string | undefined

interface Environment {
  ENV: string
  BASE_URL: string
  SIGNUP_ENDPOINT: string
  RESET_ENDPOINT: string
  LOGOUT_ENDPOINT: string
  HOTJAR_SITE_ID: number
  HUBSPOT_TRACKING_ID: number
  HOCKEYSTACK_TRACKING_ID: string
  FALLBACK_REDIRECT_URL: string
  ALLOWED_OAUTH_REDIRECTS: string[]
}

export const environment: Environment = {
  ENV: typeof APP_ENV !== 'undefined' ? APP_ENV : import.meta.env.VITE_APP_APP_ENV ?? 'local',

  BASE_URL:
    typeof API_ENDPOINT !== 'undefined'
      ? API_ENDPOINT
      : import.meta.env.VITE_APP_API_ENDPOINT ?? 'https://development-api.paddle.dev',

  SIGNUP_ENDPOINT:
    typeof SIGNUP_ENDPOINT !== 'undefined'
      ? SIGNUP_ENDPOINT
      : import.meta.env.VITE_APP_SIGNUP_ENDPOINT ?? 'https://development-vendors.paddle.dev/signup',

  RESET_ENDPOINT:
    typeof RESET_ENDPOINT !== 'undefined'
      ? RESET_ENDPOINT
      : import.meta.env.VITE_APP_RESET_ENDPOINT ?? 'https://development-vendors.paddle.dev/password/reset',

  LOGOUT_ENDPOINT:
    typeof LOGOUT_ENDPOINT !== 'undefined'
      ? LOGOUT_ENDPOINT
      : import.meta.env.VITE_APP_LOGOUT_ENDPOINT ?? 'https://development-vendors.paddle.dev/logout/signup',

  HOTJAR_SITE_ID:
    typeof HOTJAR_SITE_ID !== 'undefined' ? HOTJAR_SITE_ID : import.meta.env.VITE_APP_HOTJAR_SITE_ID ?? undefined,

  HUBSPOT_TRACKING_ID:
    typeof HUBSPOT_TRACKING_ID !== 'undefined'
      ? HUBSPOT_TRACKING_ID
      : import.meta.env.VITE_APP_HUBSPOT_TRACKING_ID ?? undefined,

  HOCKEYSTACK_TRACKING_ID:
    typeof HOCKEYSTACK_TRACKING_ID !== 'undefined'
      ? HOCKEYSTACK_TRACKING_ID
      : import.meta.env.VITE_APP_HOCKEYSTACK_TRACKING_ID ?? undefined,

  FALLBACK_REDIRECT_URL:
    typeof FALLBACK_REDIRECT_URL !== 'undefined'
      ? FALLBACK_REDIRECT_URL
      : import.meta.env.VITE_APP_FALLBACK_REDIRECT_URL ?? 'https://development-vendors.paddle.dev/',

  ALLOWED_OAUTH_REDIRECTS:
    typeof ALLOWED_OAUTH_REDIRECTS !== 'undefined'
      ? ALLOWED_OAUTH_REDIRECTS.split(',')
      : import.meta.env.VITE_APP_ALLOWED_OAUTH_REDIRECTS?.split(',') ?? [],
} as const
