import '@paddle/ant-component-library/dist/index.css'
import { createContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useSearchParams } from 'react-router-dom'

import { isValidServiceName } from 'components/shared/helpers'
import { COOKIE_REDIRECT_LOCATION_KEY } from 'constants/shared'

import { routes } from '../../constants/routes'
import { PaddleBillingRoutes } from './paddle-billing-routes'
import { PaddleClassicRoutes } from './paddle-classic-routes'

export interface AppContextValues {
  serviceName: string
  consentChallenge: string
}

export const defaultAppContext = {
  serviceName: '',
  consentChallenge: '',
}
export const AppContext = createContext<AppContextValues>(defaultAppContext)

export const Layout = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [serviceName, setServiceName] = useState('')
  const [consentChallenge, setConsentChallenge] = useState('')

  const [, setCookie] = useCookies([COOKIE_REDIRECT_LOCATION_KEY])

  useEffect(() => {
    const urlServiceName = searchParams.get('s') ?? ''
    if (isValidServiceName(urlServiceName)) {
      setServiceName(urlServiceName)
    } else {
      setServiceName('Paddle')
    }

    const redirectLocation = searchParams.get('r') ?? ''
    if (redirectLocation) {
      setCookie(COOKIE_REDIRECT_LOCATION_KEY, redirectLocation, { path: '/', maxAge: 60 * 60 * 24 })

      // remove the redirect from the URL so that we users don't store it in bookmarks
      searchParams.delete('r')
      setSearchParams(searchParams, { replace: true })
    }

    const consentChallenge = searchParams.get('consent_challenge') ?? ''
    if (consentChallenge) {
      setConsentChallenge(consentChallenge)

      // remove the consent_challenge from the URL so that we users don't store it in bookmarks
      searchParams.delete('consent_challenge')
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setSearchParams, setCookie])

  const location = useLocation()
  const isPaddleBillingPaths = [routes.signup, routes.signupPhase2].includes(location.pathname)

  return (
    <AppContext.Provider
      value={{
        serviceName,
        consentChallenge,
      }}
    >
      {isPaddleBillingPaths ? <PaddleBillingRoutes /> : <PaddleClassicRoutes />}
    </AppContext.Provider>
  )
}

export default Layout
