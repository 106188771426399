import { ArrowLeftOutlined, Button, ButtonStyles, LinkColor } from '@paddle/ant-component-library'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  SignUpLayoutConstant,
  SignUpLayoutStyledConstant,
} from 'components/pages/sign-up/sign-up-page-layout/constants'
import PaddleLogo from 'components/pages/sign-up/sign-up-page-layout/images/paddle-logo.svg?react'
import { routes } from 'constants/routes'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
`

// The logo is absolute positioned so it can float over the aside background
// so we can use flex-end to position the login link
const StyledDivLogoAndLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  margin-top: 24px;
  margin-inline: 24px;
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 72px;
    margin-top: 20px;
    margin-inline: 24px;
  }
`

// The logo changes colour based on the background
const StyledPaddleLogo = styled(PaddleLogo)`
  position: inherit;

  color: black;

  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    position: absolute;
    z-index: 101;
    top: 24px;
    left: 24px;
    color: white;
  }
`

const StyledSpanLoginText = styled.span`
  font-size: 12px;
  margin-right: 12px;
`

const StyledLinkLogin = styled(Link)`
  font-size: 12px;
`

const StyledDivBackButtonContainer = styled.div`
  width: ${SignUpLayoutStyledConstant.MainWidth};
  margin-inline: auto;
  margin-top: 64px;
  height: 32px;
`

const StyledH1 = styled.h1`
  width: ${SignUpLayoutStyledConstant.MainWidth};
  margin-inline: auto;
  margin-top: 40px;
  margin-bottom: 0px;
`

const StyledH2 = styled.h2`
  font-size: 14px;
  line-height: 20px;
  width: ${SignUpLayoutStyledConstant.MainWidth};
  margin-inline: auto;
  margin-top: 8px;
  font-weight: 400;
`

interface Props {
  currentStep: number
  title: string
  subtitle?: string
  onGoBack: () => void
}

export const SignUpHeader = (props: Props) => {
  const { currentStep, title, subtitle, onGoBack } = props

  return (
    <StyledHeader>
      <StyledDivLogoAndLoginContainer>
        <StyledPaddleLogo width="77" height="24" title={SignUpLayoutConstant.LogoAlt} />

        <span>
          <StyledSpanLoginText>{SignUpLayoutConstant.LoginText}</StyledSpanLoginText>
          <ButtonStyles
            component={
              <StyledLinkLogin to={routes.login} title={SignUpLayoutConstant.LoginButtonTitle}>
                {SignUpLayoutConstant.LoginButtonLabel}
              </StyledLinkLogin>
            }
          />
        </span>
      </StyledDivLogoAndLoginContainer>

      <StyledDivBackButtonContainer>
        {currentStep > 0 && (
          <Button
            type="link"
            linkcolor={LinkColor.Blue}
            onClick={onGoBack}
            aria-label={SignUpLayoutConstant.GoBackButtonLabel}
          >
            <ArrowLeftOutlined aria-hidden={true} />
            {SignUpLayoutConstant.GoBackButtonLabel}
          </Button>
        )}
      </StyledDivBackButtonContainer>

      <StyledH1 title={title}>{title}</StyledH1>
      {subtitle && <StyledH2>{subtitle}</StyledH2>}
    </StyledHeader>
  )
}
