import { Typography, LoadingSpinner, SpinnerSize } from '@paddle/ant-component-library'
import { useContext } from 'react'

import { AppContext } from 'components/layout/Layout'
import { useCancelConsent, useConsentInfo, useSubmitConsent } from 'components/pages/consent/hooks'
import { Container, ApiError, MainContent } from 'components/shared/components'
import { getCopyWithServiceName } from 'components/shared/helpers'

import {
  Footer,
  LoadingContainer,
  PrimaryButton,
  SecondaryButton,
  StyledLI,
  StyledTitle,
  StyledUL,
} from './consent.styled'
import { ConsentConstants } from './constants'

export const ConsentPage = () => {
  const { serviceName } = useContext(AppContext)

  const { scopes = [], isLoading, errorCode, isError: consentInfoError } = useConsentInfo()
  const {
    onClick,
    isError: submitError,
    errorCode: submitErrorCode,
    isPending: isSubmitting,
  } = useSubmitConsent(scopes)
  const onCancel = useCancelConsent()

  return (
    <MainContent>
      <Container>
        <StyledTitle>{getCopyWithServiceName(ConsentConstants.Title, serviceName)}</StyledTitle>
        <Typography.Text>{getCopyWithServiceName(ConsentConstants.Subtitle, serviceName)}</Typography.Text>

        {isLoading ? (
          <LoadingContainer>
            <LoadingSpinner size={SpinnerSize.Large} />
          </LoadingContainer>
        ) : (
          <StyledUL>
            {scopes?.map((scope) => (
              <StyledLI key={scope}>
                <Typography.Text>{scope}</Typography.Text>
              </StyledLI>
            ))}
          </StyledUL>
        )}
        <Typography.Text>{getCopyWithServiceName(ConsentConstants.LearnMore, serviceName)}</Typography.Text>
        <Footer>
          <SecondaryButton onClick={onCancel}>{ConsentConstants.Cancel}</SecondaryButton>
          <PrimaryButton type="primary" onClick={onClick} disabled={isSubmitting || isLoading}>
            {ConsentConstants.CTA}
          </PrimaryButton>
          {consentInfoError || submitError ? <ApiError errorCode={errorCode || (submitErrorCode as string)} /> : null}
        </Footer>
      </Container>
    </MainContent>
  )
}
