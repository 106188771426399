interface Copy {
  Title: string
  Subtitle?: string
}

type Info = {
  Copy: {
    [key: string]: Copy
  }
}

export const InfoConstants: Info = {
  Copy: {
    Monolith: { Title: 'Welcome back to Paddle!' },
    ProfitWell: {
      Title: 'Log in to ProfitWell with your Paddle account',
      Subtitle: 'Grow subscription revenue faster with Paddle + ProfitWell Metrics integration',
    },
    Unknown: { Title: `Log in with your Paddle account` },
  },
} as const
