interface IUbenda {
  cs: {
    api: {
      getPreferences: () => { purposes: { [key: string]: boolean } }
    }
  }
}

declare global {
  interface Window {
    _iub: IUbenda
  }
}

export const isConsentGiven = () => {
  const measurementConsentIsGiven = window._iub?.cs?.api?.getPreferences()?.purposes?.[3] || false
  return measurementConsentIsGiven
}
