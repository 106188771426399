import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk'

declare let LAUNCHDARKLY_PROXY_URL: string | undefined
declare let LAUNCHDARKLY_CLIENT_ID: string | undefined
export const ldClientId =
  typeof LAUNCHDARKLY_CLIENT_ID !== 'undefined'
    ? LAUNCHDARKLY_CLIENT_ID
    : import.meta.env.VITE_APP_LAUNCHDARKLY_CLIENT_ID
export const proxyUrl =
  typeof LAUNCHDARKLY_PROXY_URL !== 'undefined'
    ? LAUNCHDARKLY_PROXY_URL
    : import.meta.env.VITE_APP_LAUNCHDARKLY_PROXY_URL

export class LaunchDarkly {
  private static client: LDClient
  private static clientInitialized = false

  static async init() {
    const context: LDContext = {
      kind: 'user',
      anonymous: true,
    }

    if (!ldClientId) {
      if (import.meta.env.MODE !== 'test') {
        console.warn('LaunchDarkly client ID not found')
      }
      return Promise.resolve()
    }

    if (LaunchDarkly.client) {
      LaunchDarkly.clientInitialized = true
      return LaunchDarkly.client.identify(context)
    }

    // Launch darkly calls home for multiple events. Disabling all of them here.
    LaunchDarkly.client = initialize(ldClientId, context, {
      baseUrl: proxyUrl,
      eventsUrl: proxyUrl,
      streamUrl: proxyUrl,
      fetchGoals: false,
      allAttributesPrivate: true,
      diagnosticOptOut: true,
      sendEvents: false,
      disableSyncEventPost: true,
      sendLDHeaders: false,
      streaming: false,
    })
    try {
      await LaunchDarkly.client.waitForInitialization(5) // https://docs.launchdarkly.com/sdk/client-side/javascript#use-promises-to-determine-when-the-client-is-ready
      LaunchDarkly.clientInitialized = true
      return LaunchDarkly.client.identify(context)
    } catch (error) {
      LaunchDarkly.clientInitialized = false
      throw new Error('LaunchDarkly failed to initialize')
    }
  }

  static getFlag(key: string, defaultValue = false) {
    return LaunchDarkly.client?.variation(key, defaultValue)
  }

  static isInitialized() {
    return LaunchDarkly.clientInitialized
  }
}
