import { CheckCircleFilled, Checkbox, CheckboxChangeEvent, Col, Row, colors } from '@paddle/ant-component-library'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

import {
  SignupFieldHelpText,
  SignupFieldLabels,
  SignupFieldNames,
  SignupFormLayout,
  SignupWebsitePrefix,
} from 'components/pages/sign-up/constants'
import { SignupFormValues } from 'components/pages/sign-up/types'
import { InputField, LayoutType, PasswordInputField } from 'components/shared/components'

const StyledDiv = styled.div`
  margin-top: 40px;
`

const StyledRow = styled(Row)`
  .ant-form-item-extra {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.padBlue[400]};
  }
`

const StyledSpanExtra = styled.span<{ variant: 'success' | 'default' }>`
  margin-top: 8px;
  display: block;
  color: ${({ variant }) => (variant === 'success' ? colors.padGreen[900] : colors.padBlue[400])};
`

interface Props {
  isExtendedSignUp: boolean
}

export function SignUpAccountDetails(props: Props) {
  const { isExtendedSignUp } = props
  const { isSubmitting, values, setFieldValue, errors, touched } = useFormikContext<SignupFormValues>()

  return (
    <StyledDiv>
      <StyledRow gutter={[20, 40]}>
        <Col xs={24} sm={12}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.FirstName}
            id={SignupFieldNames.FirstName}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.FirstName}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.LastName}
            id={SignupFieldNames.LastName}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.LastName}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Email}
            id={SignupFieldNames.Email}
            disabled={isSubmitting}
            required={true}
            component={InputField}
            label={SignupFieldLabels.EmailOnlyText}
          />
        </Col>
        <Col span={24}>
          <Field
            layout={SignupFormLayout}
            layoutType={LayoutType.Vertical}
            name={SignupFieldNames.Password}
            id={SignupFieldNames.Password}
            disabled={isSubmitting}
            required={true}
            component={PasswordInputField}
            label={SignupFieldLabels.PasswordOnlyText}
            extra={
              !errors[SignupFieldNames.Password] && touched[SignupFieldNames.Password] ? (
                <StyledSpanExtra variant="success">
                  <CheckCircleFilled /> {SignupFieldHelpText.PasswordHelpText}
                </StyledSpanExtra>
              ) : (
                <StyledSpanExtra variant="default">{SignupFieldHelpText.PasswordHelpText}</StyledSpanExtra>
              )
            }
          />
        </Col>
        {!isExtendedSignUp ? (
          <>
            <Col span={24}>
              <Field
                layout={SignupFormLayout}
                layoutType={LayoutType.Vertical}
                name={SignupFieldNames.Website}
                id={SignupFieldNames.Website}
                disabled={isSubmitting}
                component={InputField}
                label={SignupFieldLabels.Website}
                placeholder={SignupFieldHelpText.WebsitePlaceholder}
                addonBefore={SignupWebsitePrefix}
                extra={SignupFieldHelpText.OptionalFieldText}
              />
            </Col>
          </>
        ) : null}
        <Col span={24}>
          <Checkbox
            checked={values.marketingConsent}
            onChange={(e: CheckboxChangeEvent) => setFieldValue(SignupFieldNames.MarketingConsent, e.target.checked)}
          >
            {SignupFieldLabels.MarketingConsent}
          </Checkbox>
        </Col>
      </StyledRow>
    </StyledDiv>
  )
}
