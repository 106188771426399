import '@paddle/ant-component-library/dist/index.css'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { injectHockeystack } from 'libraries/hockeystack'
import { init as initHotjar } from 'libraries/hotjar'
import { injectHubSpot } from 'libraries/hubspot'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter, isRouteErrorResponse } from 'react-router-dom'
import styled from 'styled-components'

import { useEventListener, useHotjar, useHubspot, useHockeystack } from 'components/hooks'
import { GenericErrorBoundary } from 'components/pages/generic-error-boundary/generic-error-boundary'

import Layout from '../layout/Layout'
import { GlobalStyle } from '../shared/global-styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100dvh;
`

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 60000,
    },
  },
})

const hasErrorMessage = (error: any): error is { message: string } => {
  return error && typeof error.message === 'string'
}

// https://github.com/styled-components/styled-components/issues/3738
const GlobalStyleProxy: any = GlobalStyle

export const App = () => {
  useHotjar()
  useHubspot()
  useHockeystack()
  useEventListener('consent-accepted', initHotjar)
  useEventListener('consent-accepted', injectHubSpot)
  useEventListener('consent-accepted', injectHockeystack)

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => {
        const message = isRouteErrorResponse(error)
          ? `${error.status}: ${error.statusText}`
          : hasErrorMessage(error)
            ? error.message
            : String(error)
        return <GenericErrorBoundary errorMessage={message} resetError={resetError} />
      }}
    >
      <Wrapper data-testid={'main-wrapper'}>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyleProxy />
            <BrowserRouter>
              <Layout />
            </BrowserRouter>
          </QueryClientProvider>
        </CookiesProvider>
      </Wrapper>
    </Sentry.ErrorBoundary>
  )
}

export default App
