import axios, { AxiosRequestConfig } from 'axios'
import { environment } from 'environment'

const defaultAxiosConfig: AxiosRequestConfig = {
  baseURL: environment.BASE_URL,
  responseType: 'json',
  xsrfCookieName: '',
}

export const apiClient = axios.create(defaultAxiosConfig)
