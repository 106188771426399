import { Typography } from '@paddle/ant-component-library'
import { environment } from 'environment'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { isWebUri } from 'valid-url'

import { routes } from 'constants/routes'
import { COOKIE_REDIRECT_LOCATION_KEY } from 'constants/shared'

import { Container, MainContent, Title } from '../../shared/components'
import { RedirectConstants } from './constants'

const Link = styled.a`
  text-decoration: underline;
  display: block;
  padding-top: 8px;
`
export const RedirectPage = () => {
  const [cookies, , deleteCookie] = useCookies([COOKIE_REDIRECT_LOCATION_KEY])

  let redirectLocation = cookies[COOKIE_REDIRECT_LOCATION_KEY] ?? environment.FALLBACK_REDIRECT_URL
  deleteCookie(COOKIE_REDIRECT_LOCATION_KEY)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isUndefined(redirectLocation) && (isEmpty(redirectLocation) || !isWebUri(redirectLocation))) {
      navigate(routes.login)
    }
  }, [redirectLocation, navigate])

  return (
    <MainContent data-testid={'redirect-page'}>
      <Container>
        <Title>{RedirectConstants.Title}</Title>
        <Typography.Text>{RedirectConstants.Subtitle}</Typography.Text>
        <br />
        <Link href={redirectLocation}>{redirectLocation}</Link>
      </Container>
    </MainContent>
  )
}
