import { environment } from 'environment'
import { isConsentGiven } from 'libraries/iubenda'

export const hubspotTrackingExist = () => !!document.getElementById('hs-script-loader')

export const injectHubSpot = () => {
  const hasGivenConsent = isConsentGiven()
  if (!hasGivenConsent || !environment.HUBSPOT_TRACKING_ID) return false
  if (!hubspotTrackingExist()) {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.src = `//js.hs-scripts.com/${environment.HUBSPOT_TRACKING_ID}.js`
    document.head.appendChild(script)
    return true
  }
  return false
}
