import { Button, Typography } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { Container, MainContent, Title } from '../../shared/components'
import { PermissionErrorConstants } from './constants'
import PermissionFailedSVG from './permission-failed.svg?react'

const StyledTitle = styled(Title)`
  display: block;
  margin-bottom: 20px !important;
`

const CTA = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 40px 0;

  &.ant-btn {
    padding: 4px;
  }
`

const StyledPermissionFailed = styled(PermissionFailedSVG)`
  display: block;
  margin: auto;
  margin-bottom: 32px;
`

export const PermissionErrorPage = () => {
  return (
    <MainContent data-testid={'permission-error-page'}>
      <Container>
        <StyledPermissionFailed title="Permission failed" />
        <StyledTitle>{PermissionErrorConstants.Title}</StyledTitle>
        <Typography.Text>{PermissionErrorConstants.Subtitle}</Typography.Text>

        <CTA type="primary" href="https://paddle.com/">
          {PermissionErrorConstants.CTA}
        </CTA>
      </Container>
    </MainContent>
  )
}
