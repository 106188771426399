import Hotjar from '@hotjar/browser'
import { environment } from 'environment'
import { isConsentGiven } from 'libraries/iubenda'

export const init = () => {
  const hasGivenConsent = isConsentGiven()
  if (!hasGivenConsent || !environment.HOTJAR_SITE_ID) return false
  Hotjar.init(environment.HOTJAR_SITE_ID, 6)
  return true
}
