import { Typography, colors } from '@paddle/ant-component-library'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { routes } from '../../../constants/routes'
import { AppContext } from '../../layout/Layout'
import { colors as newBrandColors } from '../../shared/colors'
import { SignUp, Login } from '../../shared/components'
import { getCopyWithServiceName, SMALL_BREAKPOINT_PX } from '../../shared/helpers'
import { InfoConstants } from './constants'
import LogoSVG from './logo-white.svg?react'

const MainContent = styled.div`
  width: 400px;
  margin: 0 auto;
  margin-top: 100px;
  padding-bottom: 72px;

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    width: 100%;
    margin-top: 83px;
    padding-left: 32px;
    padding-bottom: 0;
  }
`

const StyledLogo = styled(LogoSVG)`
  height: 46px;
  margin-bottom: 137px;

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    height: 29px;
    margin-bottom: 29px;
  }
`

const Title = styled(Typography.Text)`
  display: block;
  background-image: linear-gradient(to right, ${newBrandColors.pink}, ${newBrandColors.blue});
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 32px;

  &::selection {
    background: none;
  }

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    width: 322px;
    font-size: 28px;
    line-height: 42px;
    margin-right: 0;
  }
`

const SubTitle = styled(Typography.Text)`
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${colors.padBlue[200]};

  @media (max-width: ${SMALL_BREAKPOINT_PX}px) {
    display none;
  }
`

export const InfoPage = () => {
  const { serviceName } = useContext(AppContext)
  const location = useLocation()

  const copy = InfoConstants.Copy[serviceName] ?? InfoConstants.Copy.Unknown

  return (
    <MainContent data-testid={'info-page'}>
      <StyledLogo title="paddle-logo" />
      <Title>{getCopyWithServiceName(copy.Title, serviceName, InfoConstants.Copy.Unknown.Title)}</Title>
      {copy.Subtitle && <SubTitle>{copy.Subtitle}</SubTitle>}

      {location.pathname === routes.login ? (
        <SignUp inInfoPage={true} />
      ) : (
        <Login inInfoPage={true} existingAccount={location.pathname !== routes.passwordReset} />
      )}
    </MainContent>
  )
}
