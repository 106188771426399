import { LoadingSpinner, SpinnerSize } from '@paddle/ant-component-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useFeatureFlag } from 'components/hooks/useFeatureFlag'
import { SignUpPhase2Constant, SignUpStepsMetadata } from 'components/pages/sign-up/constants'
import { SignupFormWrapper } from 'components/pages/sign-up/sign-up-form/sign-up-form-wrapper'
import { MoRBenefits } from 'components/pages/sign-up/sign-up-mor-benefits'
import { SignUpPageLayout } from 'components/pages/sign-up/sign-up-page-layout/sign-up-page-layout'
import { SignUpTestimonials } from 'components/pages/sign-up/sign-up-testimonials'

const StyledLoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
`

export const SignUp = () => {
  useEffect(() => {
    document.title = SignUpPhase2Constant.PageTitle
  }, [])

  const { isLoading, featureFlag } = useFeatureFlag('onboarding-simplified-sign-up-form-enabled')
  const signUpVersion = featureFlag ? 'short' : 'extended'

  const [currentStep, setCurrentStep] = useState<number>(0)
  const { title, subtitle } = SignUpStepsMetadata[signUpVersion][currentStep]
  const handleGoBack = () => {
    // step 4 == public business, back button should go to step 2 == basic business details page
    // instead of step 3 ==  individual/private business page
    setCurrentStep(currentStep === 4 ? currentStep - 2 : currentStep - 1)
  }

  const handlePageChange = (page: number) => {
    setCurrentStep(page)
  }

  if (isLoading) {
    return (
      <StyledLoadingContainer>
        <LoadingSpinner size={SpinnerSize.Medium} />
      </StyledLoadingContainer>
    )
  }

  return (
    <SignUpPageLayout
      asideContent={currentStep <= 1 ? <SignUpTestimonials currentStep={currentStep} /> : <MoRBenefits />}
      asideAriaLabel={SignUpPhase2Constant.TestimonialAriaLabel}
      currentStep={currentStep}
      title={title}
      subtitle={subtitle}
      onGoBack={handleGoBack}
    >
      <SignupFormWrapper currentStep={currentStep} onPageChange={handlePageChange} signUpVersion={signUpVersion} />
    </SignUpPageLayout>
  )
}
