import { createGlobalStyle } from 'styled-components'

const lineHeightBase = '1.5'
const fontFamilyBase = `"Graphik", Helvetica, Arial, sans-serif`
const fontSizeBase = '14px'

const textColor = 'black'
const uiWhite = 'white'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Graphik';
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot');
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.woff') format('woff'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.ttf') format('truetype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Regular.svg#GraphikRegular') format('svg');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot');
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.woff') format('woff'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.ttf') format('truetype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Medium.svg#GraphikMedium') format('svg');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot');
        src: url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.woff') format('woff'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.ttf') format('truetype'),
        url('https://static.paddle.com/assets/fonts/graphik/Graphik-Semibold.svg#GraphikSemibold') format('svg');
        font-weight: 600;
        font-style: normal;
    }
    
    html, body {
      height: 100%
    }

    #root {
      width: 100%
    }

    body {
      font-family: ${fontFamilyBase};
      font-size: ${fontSizeBase};
      line-height: ${lineHeightBase};
      color: ${textColor};
      background-color: ${uiWhite};
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
       display: flex;
      align-items: stretch;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box; 
    }
  `
