import { FormItem, Select } from '@paddle/ant-component-library'
import { FieldProps } from 'formik'
import React from 'react'

import { LayoutType } from '../form'

const verticalStyle = { flexDirection: 'column' }

interface SelectProps {
  id: string
  label: string
  layout: any
  layoutType?: LayoutType
  disabled: boolean
  name: string
  required?: boolean
  colon?: boolean
  placeholder: string
  selectOptions: React.ReactNode
  defaultValue?: string
  listItemHeight?: number
  onDropdownVisibleChange?: () => undefined
  showSearch: boolean
  hideErrorMessage: boolean
  readonly setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  onChange: (value: string | number) => void
  onClear: () => void
}

export const FormSelect: React.FunctionComponent<FieldProps<any> & SelectProps> = ({
  field, // name, value, onBlur etc
  form: { setFieldValue, getFieldMeta, getFieldHelpers },
  ...props
}) => {
  const {
    label,
    id,
    layout,
    layoutType = LayoutType.Horizontal,
    required,
    colon,
    selectOptions,
    showSearch = true,
    hideErrorMessage = false,
    setFieldValue: customSetFieldValue,
    onChange = () => undefined,
    onClear = () => undefined,
    ...rest
  } = props

  const meta = getFieldMeta(field.name)
  const errorMessage = meta.touched && meta.error

  const handleSetFieldValue = (value: string | number) => {
    if (customSetFieldValue) {
      customSetFieldValue(field.name, value)
    } else {
      setFieldValue(field.name, value)
    }
  }

  const handleClear = () => {
    if (!meta.touched) {
      const helpers = getFieldHelpers(field.name)
      helpers.setTouched(true)
    }
    onClear()
  }

  const filterOption = (inputValue: string, option: any) => {
    const optionToSearch = typeof option.children === 'string' ? option.children : String(option.label)
    if (optionToSearch) {
      const selectOption = optionToSearch.toLowerCase()
      return selectOption.includes(inputValue.toLowerCase())
    } else {
      return false
    }
  }

  return (
    <FormItem
      label={label}
      htmlFor={id}
      {...layout}
      required={required}
      help={!hideErrorMessage && errorMessage ? errorMessage : undefined}
      colon={colon}
      validateStatus={errorMessage ? 'error' : undefined}
      style={layoutType === LayoutType.Vertical ? verticalStyle : {}}
    >
      <Select
        size={'large'}
        id={id}
        data-testid={id}
        //@ts-expect-error Default to field.name if specified
        name={field.name}
        {...field}
        getPopupContainer={() => document.getElementById('root') as HTMLElement}
        value={field.value === '' || field.value === null ? undefined : field.value}
        onClear={handleClear}
        onChange={(value: string | number) => {
          handleSetFieldValue(value)
          onChange(value)
        }}
        filterOption={filterOption}
        showSearch={showSearch}
        style={{ display: 'flex', flex: 1 }}
        listHeight={186}
        {...rest}
      >
        {selectOptions}
      </Select>
    </FormItem>
  )
}
