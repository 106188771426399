import { Col, Row, Typography, colors } from '@paddle/ant-component-library'
import styled from 'styled-components'

import { SignUpLayoutStyledConstant } from 'components/pages/sign-up/sign-up-page-layout/constants'

const StyledDiv = styled.div`
  position: revert;
  width: auto;
  @media (min-width: ${SignUpLayoutStyledConstant.LayoutBreakpoint}) {
    position: absolute;
    top: 288px;
    left: 45px;
    width: 330px;
  }
`

const StyledDivCallout = styled.div`
  min-height: 165px;
  padding: 24px;
  position: relative;
  background: rgb(93, 98, 96);
  background: linear-gradient(180deg, rgba(93, 98, 96, 1) 0%, rgba(118, 126, 121, 1) 95%);
  border-radius: 8px;
  &:before {
    position: absolute;
    left: 5px;
    bottom: -9px;
    content: '';
    width: 40px;
    height: 40px;
    transform: skew(184deg);
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    border-top: solid 35px #767e79;
    transform: rotate(150deg);
  }

  h4 {
    color: ${colors.uiWhite};
    font-size: 16px;
  }
  span {
    color: ${colors.uiWhite};
    font-size: 14px;
  }
`

const StyledRow = styled(Row)`
  margin-top: 40px;
`
const StyledImgLogo = styled.img`
  background: ${colors.uiWhite};
  position: absolute;
  border-radius: 4px;
  width: 32px;
  height: 32px;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: ${colors.uiWhite};
    font-size: 12px;
    line-height: 18px;
  }
`

interface Props {
  currentStep: number
}

interface AvatarProps {
  avatar: {
    logo: string
    logoStyle: React.CSSProperties
    name: string
    position: string
  }
}

const Testimonials = [
  {
    heading: `"For businesses that, like us, are product-led, self-serve, and globally applicable, you’ve got to go with a
  product like Paddle.`,
    message: `It's the only way to achieve the business goals that'll unlock the next stage, whether it's a funding milestone,
  a growth milestone, or becoming cash flow positive."`,
    avatar: {
      logo: '/images/testimonials/logos/relay.app.svg',
      logoStyle: {},
      name: 'Jacob Bank',
      position: 'CEO, Relay.app',
    },
  },
  {
    heading: `"Paddle’s been working out really well for us."`,
    message: ` We are happy that we have the right set of tools in place to serve our customers better [and] manage our day-to-day payments administration. We now have the time we need roll out even better features. I’m excited about what the future holds.`,
    avatar: {
      logo: '/images/testimonials/logos/vidyo.ai.svg',
      logoStyle: { objectFit: 'cover', objectPosition: 'left', padding: '3px', paddingLeft: '4px' },
      name: 'Vedant Maheshwari',
      position: 'CEO and Co-Founder, Vidyo.ai',
    },
  },
] as const

const Avatar = ({ avatar }: AvatarProps) => {
  const { logo, logoStyle, name, position } = avatar
  return (
    <StyledRow>
      <Col xs={3} sm={2} md={2} lg={4}>
        <StyledImgLogo src={logo} style={logoStyle} />
      </Col>
      <StyledCol xs={16} sm={20} md={21} lg={17}>
        <Typography.Text>{name}</Typography.Text>
        <Typography.Text>{position}</Typography.Text>
      </StyledCol>
    </StyledRow>
  )
}

export const SignUpTestimonials = (props: Props) => {
  const { heading, message, avatar } = Testimonials[props.currentStep]
  return (
    <StyledDiv>
      <StyledDivCallout>
        <Typography.Title level={4}>{heading}</Typography.Title>
        <Typography.Text>{message}</Typography.Text>
      </StyledDivCallout>
      <Avatar avatar={avatar} />
    </StyledDiv>
  )
}
