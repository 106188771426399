import { injectHockeystack } from 'libraries/hockeystack'
import { init as initHotjar } from 'libraries/hotjar'
import { injectHubSpot } from 'libraries/hubspot'
import { useEffect, useRef } from 'react'

export const useHotjar = () => {
  useEffect(() => {
    initHotjar()
  }, [])
}

export const useHubspot = () => {
  useEffect(() => {
    injectHubSpot()
  }, [])
}

export const useHockeystack = () => {
  useEffect(() => {
    injectHockeystack()
  }, [])
}

export const useEventListener = (
  eventType: string,
  callback: (e?: Event) => void,
  element: Window | Element = window,
) => {
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])
  useEffect(() => {
    if (element == null) return
    const handler = (e?: Event) => callbackRef.current(e)
    element.addEventListener(eventType, handler)
    return () => {
      element.removeEventListener(eventType, handler)
    }
  }, [eventType, element])
}
